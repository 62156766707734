.news-block {
  padding-bottom: 50px;
  padding-top: 100px;
  background: #ffffff;
}
.news__caption {
  font-family: HelveticaNeueCyr;
  font-size: 50.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.73;
  letter-spacing: 0.3px;
  text-align: left;
  color: #1d2a33;
  text-transform: uppercase;
}

.news__caption--marked {
  color: #3f66c2;
}
.news-content {
  position: relative;
}
.news-content::after {
  position: absolute;
  width: 100%;
  height: 2px;
  opacity: 0.2;
  bottom: 0;
  left: 0;
  content: " ";
  background-color: #3f66c2;
}

.news-item {
  width: 20%;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-item > a > img {
  max-width: 85%;
  max-height: 150px;
}

.news-carousel__item {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  text-decoration: none;
}
.news-carousel__item:hover {
  text-decoration: none;
}
.info--news-carousel i {
  color: #1d2a33;
}


.cover--news-carousel {
  width: 225px !important;
  height: 155px;
}

.info--news-carousel {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 38px;
}

.info__link--news-carousel {

}

.info__caption--news-carousel {
  max-width: 178px;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #1d2a33;
}

.info__date--news-carousel {
  font-family: HelveticaNeueCyr;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: #8c8c8c;
}

.news-carousel {
  position: relative;
  padding-top: 125px;
  //display: flex !important;
  //align-items: center;
  //justify-content: center;
  //width: 1600px;
  //max-width: 100%;
  //padding-left: 100px;
  //padding-right: 100px;
}

.news-carousel__arrow {
  position: absolute;
  top: 165px;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.news-carousel__arrow--next {
  right: -10%;
}

.news-carousel__arrow--prev {
  left: -10%;
}

.news-carousel__arrow {
  color: #647079;
  border: solid 3px #d8d9db;
}

.news-carousel__arrow:hover {
  color: #3e6ab3;
  border: solid 3px #3f66c2;
}

.news-carousel > .owl-stage-outer {
  //width: 1600px;
  //max-width: 100%;
  //width: 1600px;
  //max-width: 100%;
}
//.owl-next, .owl-prev {
//  position: absolute;
//  top: 35%;
//  font-size: 50px
//}
//
//.owl-next {
//  right: -25px
//}
//
//.owl-prev {
//  left: -25px
//}

@media all and (max-width: 1900px) {
  .cover--news-carousel {
    width: 202.5px!important;
    height: 139.5px;
  }
  .info__caption--news-carousel {
    font-size: 16px;
  }
}

@media all and (max-width: 1690px) {
  .news-carousel__arrow--next {
    right: -6.5%;
  }

  .news-carousel__arrow--prev {
    left: -5%;
  }
  .news-carousel__arrow {
    width: 50px;
    height: 50px;
  }
  .info__caption--news-carousel {
    font-size: 14px;
  }
}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .news-item>a>img {
    max-width: 80%;
  }
  .news-item {
    padding: 30px 0;
  }
}

@media all and (max-width: 1280px) {
  .news-carousel {
    padding-top: 60px;
  }
  .news__caption {
    font-size: 40.5px;
  }
  .news-carousel__arrow {
    top: 105px;
  }
  .news-item {
    width: 25%;
  }
  .news-carousel__arrow--next {
    right: -7%;
  }

  .news-carousel__arrow--prev {
    left: -6%;
  }
  .news-carousel__arrow {
    width: 50px;
    height: 50px;
  }

  .news-color-container {
    width: 900px;
  }
  .news-carousel__arrow {
    top: 75px;
  }
  //.news-carousel__item {
  //  padding-right: 0;
  //}
  .info__caption--news-carousel {
    font-size: 10px;
  }
  //.info__link--news-carousel {
  //  max-height: 17px;
  //  line-height: 1;
  //}

  .info__link--news-carousel i {
    font-size: 10px;
  }
  .info__date--news-carousel {
    font-size: 10px;
  }
  .cover--news-carousel {
    width: calc(225px * 0.6) !important;
    height: calc(155px * 0.6);
  }
  .info--news-carousel {
    padding-left: 10px;
  }
}

@media all and (min-width: 992px) and  (max-width: 1028px) {
  .news-carousel__arrow--prev {
    left: -4.4%;
  }
}
@media all and (min-width: 768px) and  (max-width: 991px) {
  .news-carousel__arrow {
    width: 35px;
    height: 35px;
  }
  .news-carousel__arrow--prev {
    left: -3.5%;
  }
  .news-carousel__item {
    justify-content: center;
  }
  .info__caption--news-carousel {
    font-size: 14px;
  }
}

@media all and (min-width: 768px) and  (max-width: 779px) {
  .news-item {
    width: 33%;
    padding: 25px 0;
  }
}

@media all and (max-width: 767px) {
  .news-item {
    width: 80%;
    padding: 25px 0;
  }
}

@media all and (min-width: 320px) and (max-width: 779px) {
  .news-block {
    padding-bottom: 40px;
    padding-top: 60px;
  }
  .news__caption {
    line-height: initial;
  }
  .news-content {
    justify-content: center;
  }
  .news-carousel {
    width: 100%;
    padding-top: 40px;
  }
  .news-color-container {
    width: 100%;
  }
  .news-carousel__item {
    padding: 25px 0;
    align-items: center;
  }
  .info--news-carousel {
    padding-left: 20px;
  }
  .cover--news-carousel {
    width: calc(162px * 0.8) !important;
    height: calc(112px * 0.8);
  }
  .info__caption--news-carousel {
    font-size: 12px;
  }
  .news-item > a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.news__caption {
  font-size: 40px;
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
  .news__caption {
    font-size: 36px;
  }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
  .news__caption {
    font-size: 34px;
  }
}

@media all and (max-width: 1280px) {
  .news__caption {
    font-size: 32px;
  }
}