.documents-block {
  padding-bottom: 100px;
  padding-top: 100px;
  background: linear-gradient(45deg, #b7c1ca, #ffffff);
}
.documents__caption {
  font-family: HelveticaNeueCyr;
  font-size: 50.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.73;
  letter-spacing: 0.3px;
  text-align: left;
  color: #1d2a33;
  text-transform: uppercase;
}

.documents-item {
  padding-top: 85px;
  padding-bottom: 85px;
}

.documents-item__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 650px;
}

.documents-item__img {
  display: flex;
  justify-content: center;
  height: 650px;
  //padding-left: 35%;
}

.documents-item__img img {
  width: 983px;
  height: 678px;
  margin-top: 50px;
  margin-left: 50px;
}

.documents-item__caption {
  padding-bottom: 70px;
  font-family: HelveticaNeueCyr;
  font-size: 40.4px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.91;
  letter-spacing: 0.2px;
  text-align: left;
  color: #3f66c2;
  text-transform: uppercase;
}

.documents-item__text-block {
  position: relative;
}

.documents-item__text-block::after {
  position: absolute;
  width: 128px;
  height: 4px;
  bottom: -30px;
  left: 0;
  content: " ";
  background-color: #3f66c2;
}
.documents-item__text-block {

}
.documents-item__text {
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: HelveticaNeueCyr;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1d2a33;
}

.documents-links {
  padding-left: 30px;
}

.documents-links__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  cursor: pointer;
  color: #1d2a33;
}

.documents-links__item:hover {
  color: #3f66c2;
  text-decoration: none;
}

.documents-links__item i {
  font-size: 20px;
  padding-right: 20px;
}
.documents-links__item p {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
  font-size: 18px;
}

@media all and (max-width: 1900px) and (min-width: 1690px) {
  .documents-item__img img {
    width: calc(983px * 0.9);
    height: calc(678px *0.9);
    margin-left: 0;
  }
  .documents-block {
    padding-bottom: 80px;
    padding-top: 90px;
  }
}

@media all and (max-width: 1690px) {
  .documents-item__img img {
    width: 786px;
    height: 542px;
    margin-top: 80px;
    margin-left: 0;
  }
}

@media (max-width: 1280px) {
  .documents__caption {
    font-size: 40.5px;
  }
  .documents-item__text {
    font-size: 18px;
  }
  .name--team-item {
    font-size: 18px;
  }
  .info--team-item {
    font-size: 14px;
  }
  .team-item__info {
    max-width: 240px;
  }
  .btn-team {
    position: relative;
    width: 350px;
    height: 54px;
    font-size: 15px;
  }
  .documents-item__img img {
    width: 707px;
    height: 488px;
    margin-top: 90px;
    margin-left: 0;
  }
}
@media all and (max-width: 1690px) and (min-width: 1280px) {
  .documents-block {
    padding-bottom: 60px;
    padding-top: 80px;
  }
  .documents-item__img {
    height: 550px;
  }
  .documents-item__img img {
    width: calc(707px * 1);
    height: calc(488px * 1);
    margin-top: 60px;
  }
  .documents-item__info {
    height: 550px;
  }
  .documents-item__text {
    font-size: 18px;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .documents-links__item p {
    font-size: 16px;
  }
  .documents-links__item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .documents-item__text-block::after {
    bottom: -40px;
  }
}

@media all and (max-width: 1280px) and (min-width: 990px) {
  .documents-block {
    padding-bottom: 60px;
    padding-top: 80px;
  }
  .documents-item__img {
    height: 450px;
  }
  .documents-item__img img {
    width: calc(707px * 0.9);
    height: calc(488px * 0.9);
    margin-top: 60px;
  }
  .documents-item__info {
    height: 450px;
  }
  .documents-links__item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .documents-item__text {
    padding-bottom: 0;
    padding-top: 0;
  }
}

@media all and (max-width: 990px) and (min-width: 768px) {
  .documents-block {
    padding-top: 60px;
  }
  .documents-item__img {
    height: 450px;
  }
  .documents-item__img img {
    margin-top: 60px;
  }
}

@media all and (min-width: 320px) and (max-width: 768px) {
  .documents-block {
    padding-top: 60px;
  }
  .advisors__caption {
    padding-left: 15px;
  }
  .documents-item__img {
    height: inherit;
  }
  .documents-item__img img {
    width: calc(300px * 1.3);
    height: calc(202px * 1.3);
    margin-top: 40px;
    margin-left: 50px;
  }
  .documents-item__info {
    justify-content: flex-start;
  }
  .documents-block {
    padding-bottom: 0px;
  }
  .documents-item__text {
    font-size: 16px;
  }
  .documents-links__item i {
    font-size: 18px;
  }
  .documents-links__item p {
    font-size: 16px;
  }
  .roadmap--doted-line {
    left: 35px;
  }
  .documents-item__info {
    height: 500px;
  }
  .documents-links__item {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

.documents__caption {
  font-size: 40px;
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
  .documents__caption {
    font-size: 36px;
  }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
  .documents__caption {
    font-size: 34px;
  }
}

@media all and (max-width: 1280px) {
  .documents__caption {
    font-size: 32px;
  }
}