@import "./partials/first-screan.scss";
@import "./partials/nav-bar.scss";
@import "./partials/langselector.scss";
@import "./partials/components.scss";
@import "./partials/definition.scss";
@import "./partials/roadmap.scss";
@import "./partials/documents.scss";
@import "./partials/news.scss";
@import "./partials/stages.scss";
@import "./partials/video-block.scss";
@import "./partials/grt-youtube-popup.css";
@import "./partials/owl.carousel.min.css";
@import "./partials/owl.theme.default.min.css";
@import "./partials/subscribe.scss";
@import "./partials/ecosystem.scss";
@import "./partials/events.scss";
@import "./partials/distribution.scss";
@import "./partials/team.scss";
@import "./partials/advisors.scss";
@import "./partials/partners.scss";
@import "./partials/feedback.scss";
@import "./partials/footer.scss";
@import "./partials/ofer_popup.scss";
@import "./partials/feedback_popup.scss";
@import "./partials/calculator_pop.scss";
@import "./partials/animate.css";
@import "./partials/magnific-popup.css";
@import "./partials/packages.scss";


@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('../fonts/HelveticaNeueCyr-Roman.eot');
  src: local('../fonts/HelveticaNeueCyr-Roman'),
  url('../fonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeueCyr-Roman.woff') format('woff'),
  url('../fonts/HelveticaNeueCyr-Roman.ttf') format('truetype');
}

@font-face {
  font-family: 'HelveticaNeueCyr-Roman';
  src: url('../fonts/HelveticaNeueCyr-Roman.eot');
  src: local('../fonts/HelveticaNeueCyr-Roman'),
  url('../fonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeueCyr-Roman.woff') format('woff'),
  url('../fonts/HelveticaNeueCyr-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  background: #083151;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.45rem + 2px);
}
//.amo-button-holder {
//  position: fixed !important;
//  z-index: 999999 !important;
//  height: 84px !important;
//  left: 70px !important;
//  bottom: 10px !important;
//}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}



@media all and (min-width: 320px) and (max-width: 750px) {
  //.amo-button-holder {
  //  left: 10px !important;
  //  bottom: 10px !important;
  //}
}
// Custom container

@media all and (min-width: 480px) {

}

@media all and (min-width: 736px) {

}

@media all and (min-width: 980px) {

}

@media all and (min-width: 1280px) {
  .nav-item {
    margin-left: 0;
    margin-right: 0;
  }
  .lang-selector {
    padding: 0 14px;
  }
}

@media all and (min-width: 1680px) {
  .container {
    width: 1480px;
    max-width: 100%;
    padding-left: 100px;
    padding-right: 100px;
  }

  .col-xlg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xlg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xlg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xlg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xlg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xlg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xlg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xlg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xlg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xlg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xlg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xlg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1900px) {
  .container {
    width: 1600px;
    max-width: 100%;
    //padding-left: 100px;
    //padding-right: 100px;
  }

  .components-item__img{
    padding-left: 190px;
  }

  .col-fhd-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-fhd-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-fhd-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-fhd-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-fhd-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-fhd-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-fhd-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-fhd-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-fhd-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-fhd-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-fhd-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-fhd-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .justify-content-between-fhd {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-resolution: 144dpi) {

}


//@media all and (min-width: 1920px) {
//
//}
//
//@media all and (min-width: 1690px) {
//
//}
//@media all and (min-width: 1280px) {
//
//}
//@media all and (min-width: 980px) {
//
//}
//@media all and (min-width: 736px) {
//
//}
//@media all and (min-width: 480px) {
//
//}