.roadmap-block {
  padding-top: 120px;
  padding-bottom: 80px;
  background: url("/img/roadmap-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  position: relative;
}

.roadmap__caption {
  padding-bottom: 86px;
  font-family: HelveticaNeueCyr;
  font-size: 50.5px;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}
.roadmap-item--hiiden {
  opacity: 0.5;
}

.roadmap-item {
  cursor: pointer;
  width: 25%;
}

.caption--roadmap-item {
  padding-bottom: 48px;
  font-family: HelveticaNeueCyr-Roman;
  font-size: 24px;
  line-height: 1.22;
  letter-spacing: 0.1px;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  max-height: 77px;
}

.roadmap-item__target {
  padding: 25px 0;
  font-family: HelveticaNeueCyr;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.8;
  letter-spacing: 0.1px;
  text-align: left;
  color: #ffffff;
}

.roadmap-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.date--target {
  color: #859ecf;
}

.roadmap-years {
  position: relative;
  height: 70px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
}

.roadmap-years::before {
  position: absolute;
  width: 100%;
  height: 6px;
  opacity: 1;
  bottom: 0;
  left: 0;
  //content: " ";
  //border-bottom-width: 2px;
  //border-bottom-style: dotted;
  //border-bottom-color: #ffffff;
}

.roadmap-years-item {
  width: 112px;
  padding-bottom: 36px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.roadmap-years-item::before {
  position: absolute;
  bottom: -10px;
  left: 10px;
  content: "";
  width: 20px;
  height: 20px;
  border: solid 2px #0ce5ff;
  border-radius: 10px;
}

.roadmap-years-item--selected::after {
  position: absolute;
  bottom: -4px;
  left: 16px;
  content: "";
  width: 8px;
  height: 8px;
  border: solid 4px #ffffff;
  border-radius: 4px;
}

.roadmap--doted-line {
  position: absolute;
  bottom: -2px;
  left: 40px;
}

.year--roadmap {
  font-family: HelveticaNeueCyr-Roman;
  font-size: 18px;
  line-height: 3.33;
  letter-spacing: 0.1px;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}

.year--roadmap:hover {
  color: #0ce5ff;
}

.y-info {
  display: none;
  //position: absolute;
  //left: 0;
  //top: 0;
  //opacity: 0;
  //visibility: hidden;
}

#y-2-info {
  display: flex;
}

.text--target-done {
  color: #0ce5ff;
}

.text--target-done::after {
  content:"\f00c";
  font-family: Font Awesome\ 5 Free;
  //display:inline-block;
  //vertical-align: top;
  //line-height: 1em;
  //width: 1em;
  //height:1em;
  //margin-right: 0.3em;
  //text-align: center;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #0ce5ff;
  font-weight: 900;
  margin-left: 10px;
}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .roadmap-item__target {
    padding: 15px 0;
  }
}

@media (max-width: 1280px) {
  .roadmap-block {
    padding-top: 90px;
    padding-bottom: 60px;
  }
  .roadmap__caption {
    font-size: 40.5px;
  }
  .caption--roadmap-item {
    padding-bottom: 35px;
    font-size: 20px;
  }
  .roadmap-item__target {
    padding: 20px 0;
    font-size: 13px;
  }
  .year--roadmap {
    font-size: 16px;
  }
}

@media all and (max-width: 1280px) and (min-width: 990px) {
  .roadmap-item__target {
    padding: 10px 0;
  }
}

@media all and (min-width: 320px) and (max-width: 990px) {
  .roadmap-block {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .roadmap-item {
    cursor: pointer;
    width: 100%;
  }
  .roadmap__caption {
    //padding-bottom: 78px;
    padding-bottom: 40px;
  }
  .roadmap-years {
    height: 150px;
    width: 100%;
    padding-bottom: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .roadmap-item__target {
    padding: 10px 0;
  }
  .roadmap-years-item {
    width: 100px;
  }
  .caption--roadmap-item {
    padding-bottom: 15px;
    padding-top: 20px;
    font-size: 20px;
  }
  .roadmap-years-item--last {
    width: auto;
  }
}

.roadmap__caption {
  font-size: 40px;
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
  .roadmap__caption {
    font-size: 36px;
  }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
  .roadmap__caption {
    font-size: 34px;
  }
}

@media all and (max-width: 1280px) {
  .roadmap__caption {
    font-size: 32px;
  }
}