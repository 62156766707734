$aquamarine : #06d6a0;
$grayish-white: linear-gradient(135deg, #05090c, #053226);

.main-screen {
    min-height: 100vh;
    padding-top: 5%;
    position: relative;
    display: flex;
    align-items: center;
    background: url("/img/img-back.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-bottom: 100px;
    //background-size: 100%;
}

.main-screen--paper {
    background-image: url("/img/img-papers.png");
    background-repeat: no-repeat;
    right: 25%;
    position: absolute;
    width: 200px;
    height: 200px;
    z-index: 30;
}

.registration-row {
    padding-left: 15px;
    padding-right: 15px;
}

.social-row {
    padding-left: 15px;
    padding-right: 15px;
    font-family: HelveticaNeueCyr;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: 0.1px;
    text-align: left;
    color: #b6d6f1;
}

.page-caption {
    font-family: HelveticaNeueCyr;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    text-align: left;
    color: #ffffff;
    text-transform: uppercase;
}

.page-caption--accent {
    color: #0ce5ff;
}

.page-text {
    padding: 2.1rem 15px;
    font-family: HelveticaNeueCyr;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: 0.1px;
    text-align: left;
    color: #b6d6f1;
}

.btn-reg {
    margin: 3% 0;
    position: relative;
    width: 421px;
    height: 64px;
    border-radius: 32px;
    border-color: #0cdaff;
    border-width: 3px;
    background-color: initial;
    font-family: HelveticaNeueCyr;
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.1px;
    color: #fefefe;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.btn-reg:hover {
    background-image: linear-gradient(to right, #122f56, #0cdaff);
    background-position: -3px 0;
    background-size: 421px 64px;
    box-shadow: 11.6px 12.4px 24px 0 rgba(12, 214, 251, 0.13);
    border-radius: 32px;
    border-color: transparent;
    border-width: 3px;
    //padding: .5rem 1rem;
    //border: 1px solid transparent;
    //background-size: 425px 64px;
    //background-position-x: -3px;
}

//.btn-reg:hover i {
//    padding-left: 3px;
//    padding-top: 3px;
//}

.btn-reg i {
    width: 10%;
    color: #fefefe;
}
.btn-reg p {
    width: 90%;
    margin-bottom: 0;
    padding-right: 8%;
    color: #fefefe;
}

.wp-download {
    padding: 4.4rem 0;
    font-family: HelveticaNeueCyr;
    font-size: 17px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: 0.1px;
    text-align: left;
    color: #ffffff;
}

.wp-download--link {
    color: #0ce5ff;
}
.wp-download--link:hover {
    color: #0ce5ff;
    text-decoration: none;
}

.icon-ppr {
  padding: 0 18px;
}
.social-row {
    align-self: flex-end;
    //padding: 3.6rem 0;
}
.social-row p {
    margin-bottom: 0;
}

.img-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -175px;
  z-index: 1;
}

@-moz-document url-prefix() {
    .img-container {
        display: block;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .img-container {
        display: block;
    }
}

.social-row__item {
    position: relative;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 22px;
    padding: 0 15px;
}

.social-row__item:hover {
    cursor: pointer;
    text-shadow: 1px 1px 10px rgba(12, 214, 251, 0.8);
    color: #0ce5ff;
}

.license {
    padding: 5px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1042;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(10, 28, 56, 0.8);
    font-family: HelveticaNeueCyr;
    font-size: 16px;
    text-transform: uppercase;
    line-height: inherit;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
}

.license-big--cover {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(10, 28, 56, 0.8);
    height: 80px;
    display: flex;
    align-items: center;
}

.license--big {
    z-index: 1000;
    display: flex;
    align-items: center;
    font-family: HelveticaNeueCyr;
    font-size: 16px;
    text-transform: uppercase;
    line-height: inherit;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
    padding-left: 15px;
    padding-right: 15px;
}

.license--big p {
    margin-bottom: 0;
}

.first-lic {
    padding-right: 30px;
}

.second-lic {
    padding-left: 30px;
}

.center-lic {
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
}

.center-lic::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background: rgba(182,213,240,.75);
    height: 25px;
    width: 2px;
}

.center-lic::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    background: rgba(182,213,240,.35);
    height: 25px;
    width: 2px;
}

.license p {
    margin-bottom: 0.5rem;
}

.license--caption {
    font-weight: 500;
}

.license--selected {
    color: #0ce5ff;
}

.space--social {
    padding-left: 23px;
    padding-right: 8px;
}

.social-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-link i {
    text-decoration: none;
}

.social-link:hover {
    text-decoration: none;
}

@media all and (min-width: 1900px) {
    .img-container {
        margin-left: -150px;
    }
    .license--big {
        flex-direction: row;
        justify-content: flex-start;
    }
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
    .main-screen {
        padding-bottom: 100px;
    }
    .img-container img {
        width: 150%;
    }
    .page-caption {
        font-size: 36px;
    }
    .page-text {
        font-size: 18px;
    }
    .wp-download {
        padding: 3.4rem 0;
    }
    .license--big {
        flex-direction: row;
        justify-content: flex-start;
    }
}

@media all and (max-width: 1690px) {
    .img-container {
        margin-left: -105px;
    }
    .img-container img {
        width: 760px;
    }
    .license--big {
        flex-direction: row;
        justify-content: flex-start;
    }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
    .img-container img {
        width: 150%;
    }
    .main-screen {
        padding-bottom: 100px;
        padding-top: 100px;
    }
    .page-caption {
        font-size: 34px;
    }
    .page-text {
        padding: 1.5rem 15px;
        font-size: 18px;
    }
    .btn-reg {
        margin: 0;
    }
    .wp-download {
        padding: 2.4rem 0;
    }
    .license--big {
        flex-direction: row;
        justify-content: flex-start;
    }
}

@media all and (max-width: 1378px) and (min-width: 1280px) {
    .main-screen {
        background-size: 100% 120%;
    }
}

@media all and (max-width: 1280px) {
    .main-screen {
        background-size: auto;
    }
    .auth-btn {
        margin: 0 5px;
    }
    .lang-selector {
        padding: 0 14px;
    }
    .main-screen {
        padding-bottom: 100px;
        padding-top: 100px;
    }
    .page-caption {
        font-size: 32px;
    }
    .page-text {
        padding: 1.7rem 15px;
        font-size: 16px;
    }
    .img-container {
        margin-left: -135px;
    }
    .img-container img {
        width: 640px;
    }
    .btn-reg {
        font-size: 15px;
        border-radius: 42px;
        width: 380px;
        height: 50px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .btn-reg:hover {
        font-size: 15px;
        width: 380px;
        height: 50px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .wp-download {
        padding: 2.9rem 0;
        font-size: 14px;
    }
    .social-row {
        font-size: 14px;
    }
    .license--big {
        font-size: 14px;
    }
}

@media all and (max-width: 1100px) {
    .main-screen {
        background-size: auto;
    }

    .img-container img {
        width: 580px;
    }
    .license--big {
        flex-direction: row;
        justify-content: flex-start;
    }
}

@media all and (min-width: 320px) and (max-width: 991px) {
    .main-screen {
        background-size: auto 120%;
    }
}

@media all and (max-width: 980px)  {
    .license--big {
        display: none;
    }
    .license {
        flex-direction: column;
    }
    .social-row p {
        width: 100%;
    }
    .main-screen {
        padding-top: 15%;
    }
    .btn-reg, .btn-reg:hover {
        width: 350px;
        height: 50px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .wp-download {
        padding: 2.4rem 0;
    }
    .justify-content-xs-center {
        justify-content: center;
    }
    .page-caption {

    }
    .page-text {

    }

    .img-container {
        margin-left: 0;
        padding-top: 40px;
        justify-content: center;
    }

    .img-container img {
        width: 105%;
    }
}

@media all and (max-width: 768px)  {

}

@media all and (min-width: 320px) and (max-width: 750px) {
    .btn-reg, .btn-reg:hover {
        width: 100%;
        background-size: 102% 64px;
        background-position: center;
    }
    .license p {
        margin-bottom: 0;
    }
}

@media all and (max-width: 480px) {
    .main-screen {
        padding-top: 20%;
    }
    .justify-content-xs-center {
        justify-content: center;
    }
    .page-caption {
        font-size: 32px;
    }
    .page-text {
        font-size: 16px;
    }

    .wp-download {
        font-size: 14px;
    }
    .social-row {
        font-size: 16px;
        text-align: center;
    }
    .social-row p {
        width: 100%;
    }

    .img-container {
        margin-left: 0;
        padding-top: 40px;
        justify-content: center;
    }
}