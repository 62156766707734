.arrow {
  border: solid #0cd6f1;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  margin-left: 10px;
}
//
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.language-selector__wrap {
  position: relative;
  width: 120px;
  height: 25px;
}
.language-selector {
  position: absolute;
  top: 0;
  left: 5%;
  width: 90%;
  padding-top: 10px;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.language-selector--open {
  border-radius: 15px;
  background: #193267;
}
.language-selector .language-list {
  display: none;
}
.language-selector--open .language-list {
  display: block;
}
.language-selector__selected-lang {
  cursor: pointer;
}
.language-list {
  list-style-type: none;
  padding-left: 0;
  margin-left: -5px;
  width: 70px;
}
.language-list__item {
  cursor: pointer;
}
.language-list__item img {
  margin-right: 5px;
}
.language-label {
  display: inline-block;
  width: 38px;
  padding: 0 6px;
  text-align: center;
  text-transform: lowercase;
}
.arrow-i {
  border: solid #0cd6f1;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}
.arrow-i--down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}