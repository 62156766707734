.stages {
  min-height: 100vh;
  background: #ffffff;
  padding-bottom: 50px;
  position: relative;
}

.distribution-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-top: 50px;
}

.marked-text {
  color: #3f66c2;
}

.currencies {
  font-weight: 900;
}

.stage-status--text {
  font-family: HelveticaNeueCyr-Roman;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.97;
  letter-spacing: 0.2px;
  text-align: left;
  color: #1d2a33;
}
.stage-status {
  padding: 0 0 40px 0;
  font-family: HelveticaNeueCyr;
  font-size: 72px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.99;
  letter-spacing: 0.4px;
  text-align: left;
  color: #1d2a33;
}

.token-number--text {
  padding-top: 20px ;
  padding-bottom: 5px ;
  font-family: HelveticaNeueCyr;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1d2a33;
}

.token-number {
  font-family: HelveticaNeueCyr;
  font-size: 106.7px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.99;
  letter-spacing: 0.5px;
  text-align: left;
  color: #3f66c2;
}

.zod-exchange {
  padding: 50px 0;
  font-family: HelveticaNeueCyr;
  font-size: 35px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.83;
  letter-spacing: 0.2px;
  text-align: left;
  color: #1d2a33;
}

.caps-block {
  padding-bottom: 49px;
}

.caps {
  padding: 10px 0;
  font-family: HelveticaNeueCyr;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1d2a33;
}

.comments {
  padding: 37px 0;
  position: relative;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1d2a33;
}

.comments::before {
  position: absolute;
  background-color: #c7d3db;
  width: 128px;
  height: 1px;
  top: 0;
  left: 0;
  content: " ";
}

.comments::after {
  position: absolute;
  background-color: #c7d3db;
  width: 128px;
  height: 1px;
  bottom: 0;
  left: 0;
  content: " ";
}

.timer {
  padding-bottom: 98px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}

.timer-element {
  padding: 0 15px;
}

.timer-element__numb {
  width: 60px;
  font-family: HelveticaNeueCyr;
  font-size: 55px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.53;
  letter-spacing: 0.3px;
  text-align: left;
  color: #3f66c2;
}

.timer-element__text {
  font-family: HelveticaNeueCyr-Roman;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.42;
  letter-spacing: 0.1px;
  text-align: center;
  color: #1d2a33;
}

.contribute-info__progress-bar {
  position: relative;
  padding: 60px 0;
}

.contribute-info__progress-bar::before {
  position: absolute;
  background-color: #c7d3db;
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
  content: " ";
}

.contribute-info__progress-bar::after {
  position: absolute;
  background-color: #c7d3db;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  content: " ";
}

.pg-bg {
  background: #0ce5ff;
  height: 30px;
  width: 100%;
  //padding: 4px 6px;
  border-radius: 15px;
}

.pg-main {
  height: 30px;
  width: 50%;
  border-radius: 15px 0 0 15px;
  background: linear-gradient(to right, #3f66c2, #3f66c2 95%, #1eb9ea);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  position: relative;
}
.pg-main__percent {
  font-family: HelveticaNeueCyr;
  font-size: 16.4px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.16;
  letter-spacing: 0.1px;
  text-align: left;
  color: #ffffff;
}

.pg-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pg-info__block {
  font-family: HelveticaNeueCyr-Roman;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.42;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1d2a33;
  text-transform: uppercase;
}

.pg-info__block--stages-status {
  font-family: HelveticaNeueCyr;
  font-size: 17.1px;
  font-weight: 500;
}

.invested-info {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.invested-info__caption {
  padding: 50px 0;
  flex: 0 0 100%;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.81;
  letter-spacing: 16px;
  text-align: center;
  color: #1d2a33;
  text-transform: uppercase;
}
.token-sale-information {
  z-index: 100;
  display: flex;
  flex-direction: column;
  //justify-content: flex-start;
  justify-content: center;
  min-height: 100vh;
  //padding-top: 100px;
  padding-left: 80px;
}

.invested-info--curr {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invested-info--curr-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 4px solid #3f66c2;
  color: #3f66c2;
  border-radius: 30px;
  font-size: 35px;
}
.invested-info--curr-number {
  padding-left: 24px;
}
.ii-cnn {
  font-family: HelveticaNeueCyr;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: 0.1px;
  text-align: right;
  color: #1d2a33;
}
.ii-cnt {
  font-family: HelveticaNeueCyr-Roman;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 0.1px;
  text-align: right;
  color: #798090;
}

.token-calculator {
  padding-top: 30px;
  font-family: HelveticaNeueCyr-Roman;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: 0.1px;
  text-align: left;
  color: #3f66c2;
  cursor: pointer;
}

.token-calculator i {
  padding-right: 15px;
  color: #1d2a33;
}

.stages--background {
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(to right, #d6e0e7, #e9f0f5);
}
.stages__comment {
  height: 117px;
  position: absolute;
  width: 50%;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f7f8;
  z-index: 1000;
}

.token-calculator--comments {
  padding-top: 0;
}

.token-calculator--text {
  color: #37393e;
}

.stages--background__text {
  transform: rotate(-90deg);
  font-family: HelveticaNeueCyr;
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #c7d3db;
  text-transform: uppercase;
  letter-spacing: 50px;
  margin-right: -230px;
}

.progress-arrow {
  position: absolute;
  right: -20px;
}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .stages {
    padding-bottom: 40px;
  }
  .stage-status {
    padding: 0 0 25px;
    font-size: 62px;
  }
  .token-number {
    font-size: 86.7px;
  }
  .token-number--text {
    padding-top: 0;
  }
  .zod-exchange {
    padding: 35px 0;
  }
  .caps {
    padding: 5px 0;
  }
  .token-calculator {
    padding-top: 15px;
  }
  .comments {
    padding: 27px 0;
    font-size: 15px;
  }
  .caps-block {
    padding-bottom: 29px;
  }
  .timer {
    padding-bottom: 68px;
  }
  .contribute-info__progress-bar {
    padding: 45px 0;
  }
}

@media all and (max-width: 1690px) and (min-width: 991px) {
  .stages {
    padding-bottom: 40px;
  }
  .stage-status {
    padding: 0 0 25px;
    font-size: 62px;
  }
  .token-number {
    font-size: 86.7px;
  }
  .token-number--text {
    padding-top: 0;
  }
  .zod-exchange {
    padding: 35px 0;
  }
  .caps {
    padding: 5px 0;
  }
  .token-calculator {
    padding-top: 15px;
  }
  .comments {
    padding: 27px 0;
    font-size: 15px;
  }
  .caps-block {
    padding-bottom: 29px;
  }
  .timer {
    padding-bottom: 68px;
  }
  .contribute-info__progress-bar {
    padding: 45px 0;
  }
  .stages__comment {
    height: 70px;
  }
  .token-calculator {
    padding-top: 0;
  }
}


@media all and (max-width: 1280px) {
  .stage-status--text {
    font-size: 26px;
  }
  .stage-status {
    padding: 0 0 30px 0;
    font-size: 62px;
  }
  .token-number {
    font-size: 80px;
  }
  .zod-exchange {
    padding: 40px 0;
    font-size: 30px;
  }
  .caps {
    font-size: 16px;
  }
  .comments {
    font-size: 16px;
  }

  .ii-cnn {
    font-size: 20px;
  }

  .invested-info--curr-logo {
    width: 50px;
    height: 50px;
  }

  .stages--background__text {
    font-size: 32px;
  }

}

@media all and (min-width: 991px) and (max-width: 1200px) {
  .timer-element__numb {
    font-size: 50px;
  }
  .timer-element {
    padding: 0 5px;
  }
}

@media all and (min-width: 769px) and (max-width: 991px) {
  .stages--background {
    height: 728px;
  }
}

@media all and (max-width: 769px) {
  .stages--background {
    height: 700px;
  }
}

@media all and (max-width: 991px)  {
  .token-number {
    font-size: 60px;
  }
  .stages__comment {
    width: 100%;
    height: 111px;
  }
  .token-sale-information {
    padding-bottom: 111px;
  }
  .token-sale-information {
    padding-left: 15px;
    //background: linear-gradient(to right, #d6e0e7, #e9f0f5);
  }
  .distribution-information {
    min-height: 60vh;
  }
  .token-sale-information {
    min-height: 100vh;
  }
  .stages--background {
    height: 800px;
  }
  .token-number--text {
    padding-top: 0;
  }
  .timer {
    padding-bottom: 70px;
  }
  .timer-element__numb {
    width: 40px;
    font-size: 40px;
  }
  .timer-element {
    padding: 0 10px;
  }
  .invested-info--curr-number {
    padding-left: 12px;
  }
  .ii-cnn {
    font-size: 14px;
  }
  .ii-cnt {
    font-size: 14px;
  }
  .stages--background {
    width: 100%;
    bottom: 0;
    right: 0;
    top: inherit;
  }
}

@media all and (max-width: 520px) {
  .stages--background {
    height: 600px;
  }
  .token-sale-information {
    min-height: initial;
    padding-top: 100px;
    height: 600px;
  }
}

@media all and (max-width: 335px) {
  .stage-status {
    font-size: 40px;
  }
  .token-number {
    font-size: 50px;
  }
  .zod-exchange {
    font-size: 25px;
  }
  .caps-block {
    padding-bottom: 20px;
  }
  .timer-element__numb {
    font-size: 35px;
  }
  .timer {
    padding-bottom: 40px;
  }
  .timer-element {
    padding: 0;
  }
  .pg-info__block--stages-status {
    font-size: 14px;
  }
  .contribute-info__progress-bar {
    padding: 30px 0;
  }
  .invested-info--curr-number {
    padding-left: 0;
  }
  .stages--background {
    height: 518px;
  }
  //.stages--background {
  //  height: 628px;
  //}
  .token-calculator {
    font-size: 13px;
  }
}