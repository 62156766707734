.team-block {
  position: relative;
  padding-bottom: 100px;
  padding-top: 100px;
  background: linear-gradient(45deg, #b7c1ca, #ffffff);
}
.team__caption {
  padding-bottom: 64px;
  font-family: HelveticaNeueCyr;
  font-size: 50.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.73;
  letter-spacing: 0.3px;
  text-align: left;
  color: #1d2a33;
  text-transform: uppercase;
}

.team__caption--marked {
  color: #3f66c2;
}

.team-container {
  height: 600px;
  overflow: hidden;
  //transition: max-height 1s ease-out;
}

.team-container--full {
  //max-height: 9999999px;
  //transition: max-height 0.2s ease-in;
}

.team-item {
  width: 33.33%;
  //height: 175px;
  margin: 32px 0;
  display: flex;
  flex-direction: row;
}

.team-item__photo {
  align-self: flex-start;
  border-radius: 50%;
  overflow: hidden;
  width: 131px;
  height: 131px;
}

.team-item__photo img {
  width: 131px;
  height: 131px;
}


.team-item__info {
  padding-left: 39px;
  padding-top: 38px;
  max-width: 248px;
}

.name--team-item {
  font-family: HelveticaNeueCyr;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1d2a33;
}

.info--team-item {
  position: relative;
  padding-bottom: 28px;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 300;
  color: #607787;
  letter-spacing: 0.1px;
  text-align: left;
}

.info--team-item--more {
  //padding-bottom: 28px;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 300;
  color: #607787;
  letter-spacing: 0.1px;
  text-align: left;
}
.info--team-item-small {
  font-size: 14px;
}

.info--team-item::after {
  position: absolute;
  width: 45%;
  height: 2px;
  bottom: 0;
  left: 0;
  content: " ";
  background-color: #3f66c2;
}

.social--team-item {
  padding-top: 29px;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 300;
  color: #b1b5b8;
  letter-spacing: 0.1px;
  text-align: left;
}

.social__item--team {
  padding-right: 15px;
}

.social__item--team:hover {
  color: #3f65c0;
}

.btn-team {
  position: relative;
  width: 410px;
  height: 64px;
  margin-top: 70px;
  border-radius: 32px;
  border-color: #3f65c0;
  border-width: 3px;
  background-color: initial;
  font-family: HelveticaNeueCyr;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #1d2a33;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-team i {
  width: 10%;
  color: #3f65c0;
}
.btn-team p {
  width: 90%;
  margin-bottom: 0;
  padding-right: 8%;
}

.btn-team:hover {
  background-image: linear-gradient(to right, #122f56, #0cdaff);
  box-shadow: 11.6px 12.4px 24px 0 rgba(12, 214, 251, 0.13);
  color: #fefefe;
  background-position: -3px 0;
  background-size: 410px 64px;
  border-radius: 32px;
  border-color: transparent;
  border-width: 3px;
}

.btn-team:hover i {
  color: #fefefe;
}

.btn-team:hover p {
  color: #fefefe;
}

.net-right {
  width: 45%;
  position: absolute;
  right: 0;
  top: 0;
}

.net-left {
  width: 45%;
  opacity: 0.2;
  position: absolute;
  left: 0;
  bottom: 0;
}

@media (max-width: 1280px) {
  .team-container {
    height: 520px;
  }
  .team-container--full {
    //max-height: 9999999px;
    //transition: max-height 0.25s ease-in;
  }

  .team__caption {
    font-size: 40.5px;
  }
  .name--team-item {
    font-size: 18px;
  }
  .info--team-item {
    font-size: 14px;
  }
  .info--team-item-small {
    font-size: 12px;
  }
  .team-item__info {
    max-width: 240px;
  }
  .btn-team {
    position: relative;
    width: 350px;
    height: 54px;
    font-size: 15px;
  }
}
@media all and (min-width: 750px) and (max-width: 1199px) {
  .team-container {
    height: 520px;
  }
  .team-container--full {
    //max-height: 9999999px;
    //transition: max-height 0.25s ease-in;
  }

  .team-item__photo, .team-item__photo img {
    width: 111px;
    height: 111px;
  }
  .team-item__info {
    padding-left: 20px;
    padding-top: 38px;
    max-width: 200px;
  }
  .name--team-item {
    font-size: 16px;
  }
  .info--team-item {
    font-size: 12px;
  }
  .info--team-item-small {
    font-size: 10px;
  }

}

@media all and (min-width: 769px) and (max-width: 991px) {
  .team-container {
    height: 700px;
  }
  .team-container--full {
    //max-height: 9999999px;
    //transition: max-height 0.25s ease-in;
  }
  .team-item {
    width: 222px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
  }
  .team-item__photo {
    align-self: inherit;
  }
  .team-item__info {
    width: 100%;
    padding-top: 25px;
    padding-left: 0;
    align-self: inherit;
  }
  .name--team-item {
    text-align: center;
  }
  .info--team-item {
    text-align: center;
    width: 100%;
  }
  .social--team-item {
    justify-content: center;
    display: flex;
  }
  .info--team-item::after {
    width: 87px;
    left: 42px;
  }
}

@media all and (min-width: 546px) and (max-width: 768px) {
  .team-container {
    height: 820px;
  }
  .team-container--full {
    //max-height: 9999999px;
    //transition: max-height 0.25s ease-in;
  }
}

@media all and (max-width: 545px) {
  .team-container {
    height: 770px;
  }
  .team-container--full {
    //max-height: 9999999px;
    //transition: max-height 0.25s ease-in;
  }
}

@media all and (min-width: 320px) and (max-width: 768px) {
  .team__caption {
    padding-bottom: 20px;
  }
  .net-left, .net-right {
    width: 65%;
  }
  .team-block {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .team-item {
    width: 172px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
  }
  .team-item__photo {
    align-self: inherit;
  }
  .team-item__info {
    width: 100%;
    padding-top: 25px;
    padding-left: 0;
    align-self: inherit;
  }
  .name--team-item {
    text-align: center;
  }
  .info--team-item {
    text-align: center;
    width: 100%;
  }
  .social--team-item {
    justify-content: center;
    display: flex;
  }
  .info--team-item::after {
    width: 87px;
    left: 42px;
  }
}

.team__caption {
  font-size: 40px;
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
  .team__caption {
    font-size: 36px;
  }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
  .team__caption {
    font-size: 34px;
  }
}

@media all and (max-width: 1280px) {
  .team__caption {
    font-size: 32px;
  }
}