.events-block {
  padding-top: 120px;
  padding-bottom: 80px;
  background: url("/img/events-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.evets__caption {
  padding-bottom: 60px;
  font-family: HelveticaNeueCyr;
  font-size: 50.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.73;
  letter-spacing: 0.3px;
  text-align: left;
  color: #1d2a33;
  text-transform: uppercase;
}

.events-item {
  width: 20%;
  padding: 38px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.events-item img {
  max-height: 100%;
}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .events-item img {
    max-height: 100%;
    max-width: 90%;
  }
}

@media (max-width: 1280px) {
  .evets__caption {
    font-size: 40.5px;
  }
  .events-item img {
    max-height: initial;
    max-width: 100%;
  }
}

@media (max-width: 1024px) {
  .events-item {
    width: 33%;
  }
  .events-item svg {
    width: 80% !important;
  }
}

@media all and (max-width: 768px) {
  .events-item {
    width: 50%;
  }
  //.events-block {
  //  padding-top: 122px;
  //  padding-bottom: 101px;
  //}
  .events-block {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .evets__caption {
    padding-bottom: 10px;
  }
}

@media all and (max-width: 546px) {
  .events-item {
    width: 80%;
  }
}

.evets__caption {
  font-size: 40px;
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
  .evets__caption {
    font-size: 36px;
  }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
  .evets__caption {
    font-size: 34px;
  }
}

@media all and (max-width: 1280px) {
  .evets__caption {
    font-size: 32px;
  }
}