.components {
  background: url('/img/components-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 130px;
  //min-height: 100vh;
}

.components__caption {
  font-family: HelveticaNeueCyr;
  font-size: 50.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.73;
  letter-spacing: 0.3px;
  text-align: left;
  color: #1d2a33;
  text-transform: uppercase;
}

.components__caption--marked {
  color: #3f66c2;
}

.components-item {
  padding-top: 85px;
  padding-bottom: 85px;
}

.components-item__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 650px;
}

.components-item__img {
  display: flex;
  justify-content: center;
  height: 650px;
  //padding-left: 35%;
}

.components-item__img img {
  height: 750px;
}

.components-item__img--bank {
  padding-top: 100px;
}

.components-item__img--bank img {
  height: 450px;
  margin-left: -150px;
}

.components-item__img-exchange {
  padding-top: 100px;
}

.components-item__img-exchange img {
  height: 650px;
  margin-left: 0;
  margin-top: -150px;
}

.components-item__img--fund img {
  height: 800px;
  margin-top: -150px;
  margin-left: -80px;
}

.components-item__img--payment {
  padding-top: 50px;
}

.components-item__img--payment img {
  height: 600px;
  margin-top: -50px;
  margin-left: -150px;
}

.components-item__img--wallet {
  padding-top: 100px;
}

.components-item__img--wallet img {
  height: 450px;
  margin-left: -150px;
}

.components-item__caption {
  padding-bottom: 70px;
  font-family: HelveticaNeueCyr;
  font-size: 40.4px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.91;
  letter-spacing: 0.2px;
  text-align: left;
  color: #3f66c2;
  text-transform: uppercase;
}

.components-item__text {
  position: relative;
}

.components-item__text::after {
  position: absolute;
  width: 128px;
  height: 4px;
  bottom: -30px;
  left: 0;
  content: " ";
  background-color: #3f66c2;
}

.components-item__text p {
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: HelveticaNeueCyr;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1d2a33;
}
.components-item__text ul li {
  font-size: 20px;
}

.components-item__arrow {
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.components-item__arrow--next {
  color: #647079;
  border: solid 3px #d8d9db;
}

.components-item__arrow--next:hover {
  color: #3e6ab3;
  border: solid 3px #3f66c2;
}

.components-item__arrow--prev {
  color: #647079;
  border: solid 3px #d8d9db;
}
.components-item__arrow--prev:hover {
  color: #3e6ab3;
  border: solid 3px #3f66c2;
}

.custom-carousel--left-nav {
  justify-content: flex-start;
  padding-left: 36px;
}

.custom-carousel--right-nav {
  justify-content: flex-end;
  padding-right: 36px;
}

.custom-carousel-indicators {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.custom-carousel-indicators li {
  height: 35px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: inherit;
  text-indent: inherit;
  text-transform: uppercase;
  background-color: inherit;
  font-family: HelveticaNeueCyr-Roman, HelveticaNeueCyr;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.81;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1d2a33;
  cursor: pointer;
}

.carousel-indicators .active {
  background-color: inherit;
  color: #3f66c2;
}
.custom-carousel-indicators .active > span {
  border-bottom: 2px solid #3f66c2;
}

@media all and (max-width: 1920px) {

}

@media all and (max-width: 1900px) and (min-width: 1690px) {
  .components {
    padding-top: 90px;
    height: 800px;
    padding-bottom: 60px;
  }
  .components-item__info {
    height: 550px;
  }
  .components-item__img {
    height: 550px;
  }
  .components-item {
    padding-top: 20px;
  }
  .components-item__info {
    height: 550px;
  }
  .components-item__caption {
    padding-bottom: 30px;
    font-size: 38px;
  }
  .components-item__text p {
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 18px;
  }
  .components-item__text ul li {
    font-size: 18px;
  }
  .components-item__text::after {
    bottom: -20px;
  }
  .components-item__img img {
    position: absolute;
    margin: 0;
  }
  .components-item__img--bank img {
    height: calc(420px * 1);
    left: -10%;
    top: 20%;
  }
  .components-item__img-exchange img {
    height: 490px;
    top: 10%;
    left: 10%;
  }
  .components-item__img--fund img {
    height: 600px;
    top: -5%;
    left: -5%;
  }
  .components-item__img--payment img {
    height: 550px;
    left: -35%;
    top: 0;
  }
  .components-item__img--card img {
    height: 500px;
    top: 15%;
    left: 1%;
  }
  .components-item__img--wallet img {
    height: 360px;
    top: 20%;
    left: -10%;
  }
}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .components {
    height: 680px;
    padding-top: 80px;
    padding-bottom: 60px;
  }
  .components-item__info {
    height: 450px;
  }
  .components-item__img {
    height: 450px;
  }
  .components-item {
    padding-top: 0;
  }
  .components-item__caption {
    padding-bottom: 30px;
    font-size: 36px;
  }
  .components-item__text p {
    padding-bottom: 0;
    padding-top: 0;
    font-size: 18px;
  }
  .components-item__text ul li {
    font-size: 18px;
  }

  .components-item__text::after {
    bottom: -20px;
  }

  .components-item__img img {
    position: absolute;
    margin: 0;
  }

  .components-item__img--bank img {
    height: 350px;
    left: -10%;
    top: 18%;
  }

  .components-item__img-exchange img {
    height: 380px;
    top: 15%;
    left: 20%;
  }

  .components-item__img--fund img {
    height: 500px;
    top: -10%;
    left: 0;
  }

  .components-item__img--payment img {
    height: 420px;
    left: -15%;
    top: 5%;
  }

  .components-item__img--card img {
    height: 450px;
    top: 15%;
    left: 1%;
  }

  .components-item__img--wallet img {
    height: 330px;
    top: 15%;
    left: -10%;
  }
}

@media all and (max-width: 1280px) and (min-width: 990px) {
  .components {
    height: 640px;
    padding-bottom: 40px;
  }
  .components-item__info {
    height: 450px;
  }
  .components-item__img {
    height: 450px;
  }
  .components {
      padding-top: 80px;
  }
  .custom-carousel-indicators {
    bottom: 20px;
  }

  .components-item {
    padding-top: 0;
  }

  .components-item__caption {
    padding-bottom: 30px;
  }

  .components-item__text p {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 5px;
    font-size: 18px;
  }
  .components-item__text ul li {
    font-size: 18px;
  }

  .components-item__text::after {
    bottom: -20px;
  }

  .custom-carousel-indicators li {
    font-size: 14px;
  }

  .custom-carousel--left-nav {
    padding-left: 0;
  }

  .custom-carousel--right-nav {
    padding-right: 0;
  }
  .components__caption {
    font-size: 40.5px;
  }
  .components-item__caption {
    font-size: 36px;
  }

  .components-item__img img {
    margin: 0;
    position: absolute;
  }

  .components-item__img--bank img {
    height: 350px;
    left: -10%;
    top: 18%;
  }

  .components-item__img-exchange img {
    height: 380px;
    top: 15%;
    left: 20%;
  }

  .components-item__img--fund img {
    height: 500px;
    top: -10%;
    left: 0;
  }

  .components-item__img--payment img {
    height: 420px;
    left: -15%;
    top: 5%;
  }

  .components-item__img--card img {
    height: 450px;
    top: 15%;
    left: 1%;
  }

  .components-item__img--wallet img {
    height: 330px;
    top: 15%;
    left: -10%;
  }
}

@media all and (min-width: 991px) and (max-width: 1199px) {
  .components-item__caption {
    font-size: 33px;
    padding-bottom: 20px;
  }
  .components-item__text p {
    font-size: 16px;
  }
  .components-item__text ul li {
    font-size: 16px;
  }

  .components-item__img img {
    margin: 0;
    position: absolute;
  }

  .components-item__img--bank img {
    height: 280px;
    left: 0%;
    top: 22%;
  }

  .components-item__img-exchange img {
    height: 380px;
    top: 15%;
    left: 10%;
  }

  .components-item__img--fund img {
    height: 500px;
    top: -10%;
    left: -10%;
  }

  .components-item__img--payment img {
    height: 300px;
    left: -5%;
    top: 19%;
  }

  .components-item__img--card img {
    height: 400px;
    top: 15%;
    left: 1%;
  }

  .components-item__img--wallet img {
    height: 280px;
    top: 15%;
    left: -2%;
  }
}

@media all and (max-width: 990px) {
  .components {
    height: auto;
  }
  .components-item__arrow--next {
    border: none;
  }

  .components-item__arrow--next:hover {
    border: none;
  }

  .components-item__arrow--prev {
    border: none;
  }
  .components-item__arrow--prev:hover {
    border: none;
  }
  .components-item__img {
    height: 350px;
  }

  .components-item__img img {
    height: 500px;
  }

  .custom-carousel-indicators li {
    font-size: 14px;
  }
  .custom-carousel--left-nav {
    padding-left: 0;
  }

  .custom-carousel--right-nav {
    padding-right: 0;
  }

  .components-item__caption {
    font-size: 40px;
  }

  .components-item__text p {
    font-size: 22px;
  }

  .components-item__info {
    height: 580px;
    justify-content: flex-start;
    padding-top: 50px;
  }

  .components-item__img {
    height: 600px;
  }
  .components {
    padding-top: 105px;
  }
  .components__caption {
    line-height: initial;
    font-size: 44px;
  }

  .custom-carousel--left-nav {
    padding-left: 0;
  }

  .custom-carousel--right-nav {
    padding-right: 0;
  }

  .components-item__caption {
    font-size: 40px;
  }

  .components-item__text p {
    font-size: 22px;
  }

  .components-item__info {
    height: 580px;
    justify-content: flex-start;
    padding-top: 50px;
  }

  .components-item__img {
    height: 600px;
  }

  .components-item__img img {
    height: 500px;
    padding-top: 0;
    padding-left: 0;
    position: absolute;
  }

  .components-item__img--bank img {
    height: 400px;
    left: 6%;
  }

  .components-item__img-exchange img {
    left: 35%;
  }

  .components-item__img--fund img {
    height: 600px;
    left: 10%;
  }

  .components-item__img--payment img {
    height: 550px;
    left: 1%;
  }

  .components-item__img--card img {
    top: 23%;
  }

  .components-item__img--wallet img {
    height: 450px;
    left: -15%;
  }

  .custom-carousel-indicators {
    bottom: inherit;
    top: 85px;
  }

  .carousel-control-prev, .carousel-control-next {
    top: 65px;
    bottom: inherit;
  }

  .components-item__arrow {
    font-size: 30px;
    font-weight: 600;
    top: 60px;
  }

  .custom-carousel-indicators li {
    display: none;
  }

  .custom-carousel-indicators > .active {
    display: flex;
  }

  .custom-carousel-indicators > .active > span {
    font-size: 24px;
    font-weight: 500;
    color: #3f66c2;
  }

  .components-item__text::after {
    content: none;
  }

  .components-item__caption {
    font-size: 40px;
  }

  .components-item__text {
    font-size: 22px;
  }
}

@media all and (min-width: 320px) and (max-width: 990px) {
  .components {
    padding-top: 60px;
  }
  .components__caption {
    font-size: 40px;
  }
  .components-item__caption {
    font-size: 30px;
    padding-bottom: 30px;
  }

  .components-item__text p {
    font-size: 16px;
    padding-bottom: 0;
    padding-top: 0;
  }
  .components-item {
    padding-bottom: 0;
  }

  .components-item__text ul li {
    font-size: 16px;
  }

  .components-item__info {
    padding-top: 0;
  }

  .custom-carousel-indicators > .active > span {
    font-size: 18px;
  }
  .components-item__arrow {
    width: 55px;
    height: 55px;
  }
  .custom-carousel-indicators {
    bottom: inherit;
    top: 75px;
  }
  .components-item__img {
    height: 300px;
  }
  .components-item__img--bank img {
    height: 200px;
    top: 25%;
    left: 40%;
  }

  .components-item__img-exchange img {
    height: 250px;
    left: 13%;
    top: 70%;
  }

  .components-item__img--fund img {
    height: 320px;
    left: 20%;
    top: 45%;
  }

  .components-item__img--payment img {
    height: 250px;
    left: 30%;
    top: 30%;
  }

  .components-item__img--card img {
    height: 260px;
    left: 5%;
    top: 20%;
  }

  .components-item__img--wallet img {
    height: 200px;
    left: 35%;
    top: 20%;
  }
}

@media all and (max-width: 480px) {

}

.components__caption {
  font-size: 40px;
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
  .components__caption {
    font-size: 36px;
  }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
  .components__caption {
    font-size: 34px;
  }
}

@media all and (max-width: 1280px) {
  .components__caption {
    font-size: 32px;
  }
}