.ecosystem {
  min-height: 100vh;
  padding-top: 120px;
  padding-bottom: 120px;
  background: url('../img/ecosystem-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

}

.ecosystem__caption {
  font-family: HelveticaNeueCyr;
  font-size: 50.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.73;
  letter-spacing: 0.3px;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}

.ecosystem__caption--marked {
  color: #0ce5ff;
}

.ecosystem-scheme__container {
  padding-top: 100px;
}

.ecosystem-scheme__container img {
  height: 80vh;
  max-height: 808px;
}

@media all and (min-width: 1920px) {
  .ecosystem {
    background-size: cover;
  }
}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .ecosystem {
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
  .ecosystem-scheme__container {
    padding-top: 5vh;
  }
  .ecosystem-scheme__container img {
    height: 75vh;
  }
}

@media all and (max-width: 1280px) {
  .ecosystem__caption {
    font-size: 40.5px;
  }
  .ecosystem {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .ecosystem-scheme__container {
    padding-top: 30px;
  }
  .ecosystem-scheme__container img {
    height: 500px;
  }
}

@media all and (max-width: 768px)  {
  .ecosystem-scheme__container img {
    height: 450px;
  }
}

@media all and (min-width: 320px) and (max-width: 750px) {
  .ecosystem__caption {
    font-size: 40px;
    line-height: initial;
  }
  .ecosystem-scheme__container img {
    max-width: 100%;
    max-height: 70vh;
  }
  .ecosystem {
    min-height: inherit;
  }
  .ecosystem-scheme__container img {
    height: 250px;
  }
}

.ecosystem__caption {
  font-size: 40px;
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
  .ecosystem__caption {
    font-size: 36px;
  }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
  .ecosystem__caption {
    font-size: 34px;
  }
}

@media all and (max-width: 1280px) {
  .ecosystem__caption {
    font-size: 32px;
  }
}