.definition-block {
  position: relative;
  padding-bottom: 100px;
  padding-top: 100px;
  background: #ffffff;
  overflow: hidden;
}
.definition__caption {
  padding-left: 15px;
  font-family: HelveticaNeueCyr;
  font-size: 50.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.73;
  letter-spacing: 0.3px;
  text-align: left;
  color: #1d2a33;
  text-transform: uppercase;
}

.definition__caption--marked {
  color: #3f65c0;
}

.definition-text {
  font-family: HelveticaNeueCyr;
  font-size: 22px;
  font-weight: 300;
  text-align: left;
  color: #1d2a33;
  line-height: 1.55;
  letter-spacing: 0.1px;
}

.definition-text--box {
  padding-top: 100px;
}

.coin-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.main-coin {
  width: 642px;
  height: 617px;
  margin-top: -50px;
}

.def-coin-5 {
  position: absolute;
  width: 485px;
  height: 578px;
  bottom: -460px;
  left: 35%;
}


.def-coin-1 {
  position: absolute;
  width: 172px;
  height: 275px;
  top: 100px;
  left: -50px;
}

.def-coin-2 {
  margin-top: -100px;
  width: 283px;
  height: 333px;
}

.def-coin-3 {
  position: absolute;
  right: -30px;
  top: 400px;
  width: 159px;
  height: 146px;
}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .definition-block {
    padding-bottom: 80px;
    padding-top: 80px;
  }
  .definition__caption {
    font-size: 40.5px;
  }
  .definition-text--box {
    padding-top: 30px;
  }
  .definition-text {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .main-coin {
    width: calc(642px*0.7);
    height: calc(617px*0.7);
    margin-top: -25px;
  }
  .def-coin-5 {
    position: absolute;
    width: calc(485px*0.4);
    height: calc(578px*0.4);
    bottom: -150px;
    left: 45%;
  }
  .def-coin-1 {
    position: absolute;
    width: calc(172px*0.7);
    height: calc(275px*0.7);
    top: 100px;
    left: -70px;
  }

  .def-coin-2 {
    margin-top: -45px;
    width: calc(283px*0.6);
    height: calc(333px*0.6);
  }

  .def-coin-3 {
    position: absolute;
    right: -30px;
    top: 400px;
    width: calc(159px*0.7);
    height: calc(146px*0.7);
  }
}

@media (max-width: 1280px) {
    .main-coin {
    width: calc(642px*0.7);
    height: calc(617px*0.7);
    margin-top: -25px;
  }
  .def-coin-2 {
    margin-top: -45px;
    width: calc(283px*0.6);
    height: calc(333px*0.6);
  }
  .def-coin-5 {
    position: absolute;
    width: calc(485px*0.4);
    height: calc(578px*0.4);
    bottom: -150px;
    left: 45%;
  }
  .definition__caption {
    font-size: 40.5px;
  }
  .definition-text {
    font-size: 15px;
  }
}

@media all and (max-width: 1100px) {
  .main-coin {
    width: calc(642px*0.7);
    height: calc(617px*0.7);
    margin-top: -25px;
  }

  .def-coin-5 {
    position: absolute;
    width: calc(485px*0.6);
    height: calc(578px*0.6);
    bottom: -250px;
    left: 35%;
  }


  .def-coin-1 {
    position: absolute;
    width: calc(172px*0.7);
    height: calc(275px*0.7);
    top: 100px;
    left: -70px;
  }

  .def-coin-2 {
    margin-top: -100px;
    width: calc(283px*0.7);
    height: calc(333px*0.7);
  }

  .def-coin-3 {
    position: absolute;
    right: -30px;
    top: 400px;
    width: calc(159px*0.7);
    height: calc(146px*0.7);
  }
}

@media all and (max-width: 1279px) and (min-width: 991px) {
  .definition-block {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .definition__caption {
    font-size: 40.5px;
  }
  .definition-text--box {
    padding-top: 30px;
  }
  .definition-text {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .def-coin-1 {
    position: absolute;
    width: calc(172px*0.6);
    height: calc(275px*0.6);
    top: 100px;
    left: -70px;
  }
  .main-coin {
    width: calc(642px*0.7);
    height: calc(617px*0.7);
    margin-top: -25px;
  }

  .def-coin-5 {
    position: absolute;
    width: calc(485px*0.5);
    height: calc(578px*0.5);
    bottom: -220px;
    left: 35%;
  }

  .def-coin-2 {
    margin-top: -30px;
    width: calc(283px*0.7);
    height: calc(333px*0.7);
  }

  .def-coin-3 {
    position: absolute;
    right: -45px;
    top: 400px;
    width: calc(159px*0.7);
    height: calc(146px*0.7);
  }
}

@media all and (max-width: 980px) {
  .definition-block {
    padding-top: 60px;
    padding-bottom: 50px;
  }
  .definition-text--box {
    padding-top: 40px;
  }
  .definition__caption {
    line-height: inherit;
  }
  .def-coin-2 {
    display: none;
  }
  .def-coin-2--mobail {
    position: absolute;
    right: -250px;
    top: 135px;
    width: 283px;
    height: 333px;
  }

  .def-coin-1 {
    top: 500px;
    left: -90px;
  }

  .def-coin-5 {
    position: absolute;
    width: calc(485px*0.3);
    height: calc(578px*0.3);
    bottom: -140px;
    left: -70px;
  }
  .def-coin-3 {
    top: inherit;
    right: -30px;
    bottom: -35px;
    width: 132px;
    height: 126px;
  }
  .main-coin--mobail {
    width: calc(487px*0.6);
    height: calc(436px*0.6);
  }

  .definition-text--box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.definition__caption {
  font-size: 40px;
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
  .definition__caption {
    font-size: 36px;
  }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
  .definition__caption {
    font-size: 34px;
  }
}

@media all and (max-width: 1280px) {
  .definition__caption {
    font-size: 32px;
  }
}