.feedback-popup {
  position: relative;
  max-width: 845px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 20px auto;
}

.noresize {
  resize: none;
}

.feedback-popup > button.mfp-close {
  height: 60px;
  width: 60px;
  background: #0ce5ff;
}
.feedback-popup__caption {
  height: 262px;
  width: 100%;
  border-radius: 15px 0 0 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: HelveticaNeueCyr;
  font-size: 50px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background: url('/img/feedback-popup_bg.png');
  background-position: center;
  text-transform: uppercase;
}
.feedback-popup__caption--selected {
  color: #26e7fe;
  border-bottom: 2px solid #26e7fe;
}

.feedback-popup__body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 70px 0;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
  background: #ffffff;
  font-family: HelveticaNeueCyr-Roman;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1px;
  text-align: left;
  color: #111111;
}

.feedback-popup--form {
  width: 50%;
  font-family: HelveticaNeueCyr-Roman;
  font-size: 16px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1d2a33;
}

.feedback-popup--form-control {
  border-radius: 32px;
  border: solid 3px #c7d4f5;
  font-family: HelveticaNeueCyr-Roman;
  font-size: 16px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1d2a33;
  text-indent: 20px;
}

.form-control--small {
  height: 64px;
}

.btn-feedback-popup {
  margin: 3% 0;
  position: relative;
  width: 410px;
  height: 64px;
  border-radius: 32px;
  border-color: #3f65c0;
  border-width: 3px;
  background-color: initial;
  font-family: HelveticaNeueCyr;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.1px;
  color: #3f65c0;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-feedback-popup i {
  color: #3f65c0;
}

.btn-feedback-popup:hover {
  background-image: linear-gradient(to right, #122f56, #0cdaff);
  background-position: -3px 0;
  background-size: 410px 64px;
  box-shadow: 11.6px 12.4px 24px 0 rgba(12, 214, 251, 0.13);
  border-radius: 32px;
  border-color: transparent;
  border-width: 3px;
  //padding: .5rem 1rem;
  //border: 1px solid transparent;
  //background-size: 425px 64px;
  //background-position-x: -3px;
}

//.btn-feedback-popup:hover i {
//    padding-left: 3px;
//    padding-top: 3px;
//}

.btn-feedback-popup i {
  width: 10%;
}
.btn-feedback-popup p {
  color: #1d2a33;
  width: 90%;
  margin-bottom: 0;
  padding-right: 8%;
}

.form-control-danger {
  background-repeat: no-repeat;
  background-position: center right .5625rem;
  background-size: 1.125rem 1.125rem;
  border-color: #d9534f;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d9534f' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
}

.form-control-success {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right .5625rem;
  background-size: 1.125rem 1.125rem;
  border-color: #5cb85c;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}


@media all and (max-width: 1690px) and (min-width: 1280px) {
  .feedback-popup__caption {
    height: 162px;
  }
  .feedback-popup__body {
    padding: 30px 0 0;
    font-size: 18px;
  }
  .form-control--small {
    height: 44px;
  }
}


@media all and (max-width: 1280px) {
  .feedback-popup__caption {
    height: 162px;
  }
  .feedback-popup__body {
    padding: 30px 0 0;
    font-size: 18px;
  }
  .form-control--small {
    height: 44px;
  }
}

@media all and (min-width: 320px) and (max-width: 990px) {
  .feedback-popup {
    margin-top: 75px;
  }
  .feedback-popup__body {
    font-size: 16px;
  }
  .feedback-popup__caption {
    font-size: 36px;
  }
  .calc-result__caption {
    font-size: 18px;
  }
  .btn-feedback-popup {
    width: 90%;
    height: 54px;
  }
  .feedback-popup>button.mfp-close {
    height: 40px;
    width: 40px;
  }
  .feedback-popup--form {
    width: 90%;
  }
  .feedback-popup__body p {
    width: 90%;
  }
}