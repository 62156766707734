.footer-block {
  height: 102px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-top: 1px solid rgba(182,213,240,.25);
  font-family: HelveticaNeueCyr-Roman;
  font-size: 16px;
  text-align: left;
  color: #5d8aaf;
}

.footer__copyright {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.copyright--text {
  padding-left: 10px;
}
.footer__links {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.footer__links a {
  padding: 0px 10px;
  font-family: HelveticaNeueCyr-Roman;
  font-size: 16px;
  text-align: left;
  color: #5d8aaf;
}

.footer__links a:hover {
  text-decoration: none;
}

.footer__links--left, .footer__links--right {
  position: relative;
}

.footer__links--left::after {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(182,213,240,.25);
  content: '';
  height: 25px;
  width: 1px;
}
.footer__links--right::before {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(182,213,240,.25);
  content: '';
  height: 25px;
  width: 1px;
}
.footer__design {
  width: 15%;
}

@media all and (max-width: 768px)  {
  .footer-block {
    justify-content: center;
    align-items: center;
  }
  .copyright--text {
    font-size: 22px;
  }
}

@media all and (min-width: 320px) and (max-width: 750px) {
  .feedback-block {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .copyright--text {
    font-size: 16px;
  }
}