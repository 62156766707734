input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: #fefefe;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);
}

.feedback-block {
  padding-top: 120px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  background: url("/img/feedback-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.feedback-row {
  padding-left: 7%;
  width: 75%;
}

.feedback__caption {
  padding-bottom: 86px;
  font-family: HelveticaNeueCyr;
  font-size: 50.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.73;
  letter-spacing: 0.3px;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}

.feedback__social {
  position: relative;
}

.feedback__social::after {
  position: absolute;
  width: 100%;
  height: 2px;
  min-height: 1px;
  opacity: 0.2;
  bottom: 0;
  left: 0;
  content: " ";
  background-color: #ffffff;
  transform: scale(1,1);
  //background: url('../img/f1.png');
}

.feedback__social--text {
  font-family: HelveticaNeueCyr-Roman;
  font-size: 22px;
  font-weight: normal;
  text-align: left;
  color: #ffffff;
}

.social-row--feedback {
  padding: 60px 0;
  display: flex;
  flex-direction: row;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #b6d6f1;
}

.social-row__item--feedback {
  padding-left: 0;
  padding-right: 77px;
  position: relative;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 22px;
}

.social-row__item--feedback:hover {
  cursor: pointer;
  text-shadow: 1px 1px 10px rgba(12, 214, 251, 0.8);
  color: #0ce5ff;
}

.feedback__subscribe {

}

.feedback__subscribe--text {
  padding: 60px 0;
  font-family: HelveticaNeueCyr;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  color: #b6d6f1;
}

.subscribe-box {
  border: solid 3px #0ce5ff;
  border-radius: 35px;
  margin-bottom: 80px;
  height: 67px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.subscribe-box--input {
  width: 615px;
  padding-left: 38px;
  background-color: transparent;
  border: none !important;
  font-family: HelveticaNeueCyr-Roman;
  font-size: 19px;
  text-align: left;
  color: #ffffff;
}

.subscribe-box--input::-webkit-input-placeholder {color:rgba(182, 214, 241, 0.2);}
.subscribe-box--input::-moz-placeholder          {color:rgba(182, 214, 241, 0.2);}/* Firefox 19+ */
.subscribe-box--input:-moz-placeholder           {color:rgba(182, 214, 241, 0.2);}/* Firefox 18- */
.subscribe-box--input:-ms-input-placeholder      {color:rgba(182, 214, 241, 0.2);}

.subscribe-box--input:focus{
  outline: none;
}

.subscribe-box--btn {
  width: 315px;
  height: 67px;
  margin: 0 -3px 0 0 !important;
  padding: 0 !important;
  border: none;
  border-radius: 0 37px 37px 0;
  font-family: HelveticaNeueCyr;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  background: none;
  color: #b6d6f1;
  cursor: pointer;
  justify-content: center;
}

.subscribe-box--btn:hover {
  color: #fefefe;
  background-image: linear-gradient(to right, #0de2fc, #e7733a);
}


.subscribe-suc-text {
  display: none;
  font-family: HelveticaNeueCyr-Roman;
  font-size: 22px;
  font-weight: 400;
  text-align: left;
  color: #fff;
}

.subscribe-box--input.error {

}

#footSubscribe {
  display: flex;
  position: relative;
  width: 100%;
  text-align: center;
}

label.error {
  position: absolute;
  width: 100%;
  bottom: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #cc5490;
  border-radius: 30px;
  height: 30px;
}

.subscribe-box--input.error {
  display: flex !important;
}

.hidden-form {
  display: none !important;
}

.f-link:hover {
  cursor: pointer;
  text-shadow: 1px 1px 10px rgba(12, 214, 251, 0.8);
  color: #0ce5ff;
}

.subscribe-box--input::-moz-placeholder {
  color: #b6d6f1;
  opacity: 0.4;
}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .feedback-block {
    padding-top: 80px;
  }
  .feedback__caption {
    padding-bottom: 66px;
  }
  .feedback-row {
    padding-left: 6%;
  }
  .feedback__social--text {
    font-size: 20px;
  }
  .social-row--feedback {
    padding: 45px 0;
  }
}

@media (max-width: 1280px) {
  .feedback__caption {
    font-size: 40.5px;
  }
  .feedback__social--text {
    font-size: 20px;
  }
  .feedback__subscribe--text {
    font-size: 17px;
  }
  .subscribe-box--btn {
    font-size: 16px;
  }
}

@media all and (max-width: 768px)  {
  .feedback__caption {
    font-size: 50px;
  }

  .feedback-row {
    padding-left: 0;
    width: 100%;
  }

  .feedback__social {
    padding-left: 0;
    padding-right: 0;
  }
  .feedback__social--text {
    font-size: 25px;
  }
  .social-row--feedback > i {
    width: 25%;
  }
}

@media all and (min-width: 320px) and (max-width: 768px) {
  .feedback-block {
    //padding-top: 91px;
    padding-top: 60px;
  }
  .feedback-row {
    margin-right: 0;
    margin-left: 0;
  }
  .feedback__caption {
    font-size: 40px;
    padding-bottom: 42px;
  }
  .social-row--feedback {
    padding: 30px 0;
  }
  .feedback__social--text {
    font-size: 20px;
  }
  .subscribe-box {
    border: none;
  }
  #footSubscribe {
    flex-direction: column;
    align-items: center;
  }
  .subscribe-box--input {
    border: 3px solid #0ce5ff !important;
    border-radius: 35px;
    width: 100%;
    height: 60px;
  }
  .subscribe-box--btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .subscribe-box--btn:hover {
    color: #b6d6f1;
    background-image: none;
  }
  .subscribe-box--btn i {
    font-size: 22px;
    color: #fefefe;
  }

  .social-row--feedback {
    flex-wrap: wrap;
    justify-content: center;
  }
  .social-row__item--feedback-container {
    font-size: 24px;
    padding: 5% 5%;
  }
  .social-row__item--feedback {
    padding: 0;
    font-size: 28px;
  }
}

.feedback__caption {
  font-size: 40px;
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
  .feedback__caption {
    font-size: 36px;
  }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
  .feedback__caption {
    font-size: 34px;
  }
}

@media all and (max-width: 1280px) {
  .feedback__caption {
    font-size: 32px;
  }
}
