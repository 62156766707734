.distribution-block {
  padding-top: 120px;
  padding-bottom: 80px;
  //padding-bottom: 0px;
  background: url("/img/distribution-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.distribution__caption {
  padding-bottom: 86px;
  font-family: HelveticaNeueCyr;
  font-size: 50.5px;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}

.distribution__caption--marked {
  color: #0ce5ff;
}

.distr-row {
  padding-left: 16%;
  list-style: none;
}

.distr-item {
  width: 25%;
  padding-left: 35px;
  margin-right: 90px;
}

.caption--distr-item {
  width: 300px;
  height: 100px;
  position: relative;
  font-family: HelveticaNeueCyr-Roman;
  font-size: 25px;
  text-align: left;
  color: #ffffff;
  padding-bottom: 31px;
}

.caption--distr-item::after {
  position: absolute;
  width: 128px;
  height: 4px;
  bottom: 0;
  left: 0;
  content: " ";
  background-color: #0ce5ff;
}

//.caption--distr-item-small {
//  width: 220px;
//}

.legend--distr-item {
  height: 320px;
  display: flex;
  flex-wrap: wrap;
  padding: 78px 0;
  align-items: flex-start;
  align-content: flex-start;
}

.legend-item {
  padding: 15px 0;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.legend-item__color {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #ffffff;
}

.legend-item__color--orange {
  background: #d08a38;
}

.legend-item__color--lite-blue {
  background: #10b4f1;
}
.legend-item__color--blue {
  background: #527cf0;
}

.legend-item__color--gray-1 {
  background: #3d5067;
}

.legend-item__color--gray-2 {
  background: #6b798f;
}

.legend-item__color--gray-3 {
  background: #a4abbe;
}

.legend-item__percent {
  padding-left: 20px;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
}

.legend-item__text {
  max-width: 105px;
  padding-left: 5px;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
}

.diagram--distr-item {
  margin-left: -35px;
  position: relative;
  height: 275px;
  width: 362px;
}

.diagram--distr-item img {
  position: absolute;
  transition: all 1s ease;
}

.dg-1-1 {
  left: 0;
  top: 0;
}
.dg-1-1:hover {
  left: -5px;
  top: 5px;
}

.dg-1-2 {
  left: 175px;
  top: -20px;
}
.dg-1-2:hover {
  left: 180px;
  top: -25px;
}

.dg-1-3 {
  left: 220px;
  top: 0;
}
.dg-1-3:hover {
  left: 225px;
  top: -5px;
}

.dg-1-4 {
  left: 315px;
  top: 30px;
}
.dg-1-4:hover {
  left: 320px;
  top: 35px;
}

.dg-2-1 {
  z-index: 10;
  left: 0;
  top: 95px;
}
.dg-2-1:hover {
  left: 0;
  top: 100px;
}

.dg-2-2 {
  left: 15px;
  top: -15px;
  z-index: 6;
}
.dg-2-2:hover {
  left: 10px;
  top: -20px;
}

.dg-2-3 {
  left: 170px;
  top: -35px;
}
.dg-2-3:hover {
  left: 175px;
  top: -40px;
}

.dg-2-4 {
  left: 195px;
  top: -18px;
}
.dg-2-4:hover {
  left: 200px;
  top: -23px;
}

.dg-2-5 {
  left: 210px;
  top: 0;
}
.dg-2-5:hover {
  left: 215px;
  top: -5px;
}

.dg-2-6 {
  left: 295px;
  top: 15px;
}
.dg-2-6:hover {
  left: 300px;
  top: 10px;
}

//third diagram

.dg-3-1 {
  left: 205px;
  top: 100px;
}
.dg-3-1:hover {
  left: 210px;
  top: 95px;
}

.dg-3-2 {
  left: 15px;
  top: -15px;
}
.dg-3-2:hover {
  left: 10px;
  top: -20px;
}

.dg-3-3 {
  left: 130px;
  top: -35px;
}
.dg-3-3:hover {
  left: 135px;
  top: -40px;
}

.dg-3-4 {
  left: 160px;
  top: -18px;
}
.dg-3-4:hover {
  left: 165px;
  top: -23px;
}

.dg-3-5 {
  left: 220px;
  top: -5px;
}
.dg-3-5:hover {
  left: 225px;
  top: -10px;
}

.dg-3-6 {
  left: 305px;
  top: 10px;
}
.dg-3-6:hover {
  left: 310px;
  top: 5px;
}

//third diagram end

.distr--left-nav {
  display: none;
}

.distr--right-nav {
  display: none;
}

@media all and (max-width: 1900px) and (min-width: 1690px) {
  .distr-row {
    padding-left: 14%;
  }
  .distr-item {
    margin-right: 25px;
    width: 30%;
  }
}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .distr-row {
    padding-left: 14%;
  }

  .distribution-block {
    padding-top: 100px;
    padding-bottom: 60px;
  }

  .legend--distr-item {
    height: 220px;
    align-items: flex-start;
    padding: 36px 0;
  }
  .distr-item {
    margin-right: 10px;
    width: 32%;
  }
  .caption--distr-item {
    height: 100px;
    font-size: 23px;
    margin-top: 20px;
  }
  .legend-item__percent, .legend-item__text {
    font-size: 14px;
  }

  .distribution__caption {
    font-size: 40.5px;
    padding-bottom: 30px;
  }
  .diagram--distr-item {
    height: 233px;
    width: 307px;
  }
  .dg-1-1 {
    left: 0;
    top: 0;
    height: 299px;
    width: 335px;
  }
  .dg-1-1:hover {
    left: -5px;
    top: 5px;
  }

  .dg-1-2 {
    left: 125px;
    top: -15px;
    height: 141px;
    width: 111px;
  }
  .dg-1-2:hover {
    left: 130px;
    top: -20px;
  }

  .dg-1-3 {
    left: 150px;
    top: 0;
    height: 165px;
    width: 169px;
  }
  .dg-1-3:hover {
    left: 155px;
    top: -5px;
  }

  .dg-1-4 {
    left: 215px;
    top: 15px;
    height: 160px;
    width: 141px;
  }
  .dg-1-4:hover {
    left: 220px;
    top: 10px;
  }

  .dg-2-1 {
    width: 342px;
    height: 225px;
    left: 0;
    top: 65px;
  }
  .dg-2-1:hover {
    left: -5;
    top: 70px;
  }
  .dg-2-2 {
    width: 200px;
    height: 226px;
    left: 10px;
    top: -15px;
  }
  .dg-2-2:hover {
    left: 15px;
    top: -10px;
  }

  .dg-2-3 {
    width: 105px;
    height: 136px;
    left: 120px;
    top: -30px;
  }
  .dg-2-3:hover {
    left: 125px;
    top: -35px;
  }

  .dg-2-4 {
    width: 109px;
    height: 138px;
    left: 137px;
    top: -18px;
  }
  .dg-2-4:hover {
    left: 142px;
    top: -23px;
  }

  .dg-2-5 {
    width: 151px;
    height: 154px;
    left: 145px;
    top: -5;
  }
  .dg-2-5:hover {
    left: 150px;
    top: -10px;
  }

  .dg-2-6 {
    width: 140px;
    height: 160px;
    left: 205px;
    top: 5px;
  }
  .dg-2-6:hover {
    left: 210px;
    top: 0;
  }
  //third diagram

  .dg-3-1 {
    width: 200px;
    height: 230px;
    left: 150px;
    top: 60px;
  }
  .dg-3-1:hover {
    left: 155px;
    top: 65px;
  }

  .dg-3-2 {
    width: 226px;
    height: 296px;
    left: 15px;
    top: -15px;
  }
  .dg-3-2:hover {
    left: 10px;
    top: -20px;
  }

  .dg-3-3 {
    width: 109px;
    height: 137px;
    left: 95px;
    top: -30px;
  }
  .dg-3-3:hover {
    left: 100px;
    top: -35px;
  }

  .dg-3-4 {
    width: 137px;
    height: 137px;
    left: 115px;
    top: -18px;
  }
  .dg-3-4:hover {
    left: 120px;
    top: -23px;
  }

  .dg-3-5 {
    width: 153px;
    height: 155px;
    left: 158px;
    top: -10px;
  }
  .dg-3-5:hover {
    left: 163px;
    top: -15px;
  }

  .dg-3-6 {
    width: 136px;
    height: 156px;
    left: 220px;
    top: 0;
  }
  .dg-3-6:hover {
    left: 225px;
    top: -5px;
  }

  .custom-item {
    width: 40%;
  }

  //third diagram end
}

@media all and (max-width: 1537px) and (min-width: 1281px) {
  .distribution-block {
    padding-top: 70px;
    padding-bottom: 40px;
  }
  .distribution__caption {
    padding-bottom: 5px;
  }
  .distr-row {
    padding-left: 11%;
  }
  .caption--distr-item {
    margin-top: 10px;
    height: 85px;
    font-size: 21px;
  }
  .legend--distr-item {
    height: 190px;
    padding: 20px 0;
  }
  .legend-item {
    padding: 10px 0;
  }
}

@media all and (max-width: 1421px) and (min-width: 1281px) {
  .distr-row {
    padding-left: 8%;
  }
}

@media all and (max-width: 1280px) {
  .distribution__caption {
    font-size: 40.5px;
  }
  //.diagram--distr-item img {
  //  width: 100%;
  //}

  .diagram--distr-item {
    margin-top: 15px;
  }
  .distr-row {
    padding-left: 5%;
  }
  .legend-item__percent {
    padding-left: 15px;
    font-family: HelveticaNeueCyr;
    font-size: 14px;
  }
  .legend-item__text {
    padding-left: 5px;
    font-size: 14px;
  }
  .distr-item {
    margin-right: 10px;
    width: 30%;
  }

  .diagram--distr-item {
    height: 233px;
    width: 307px;
  }
  .dg-1-1 {
    left: 0;
    top: 0;
    height: 299px;
    width: 335px;
  }
  .dg-1-1:hover {
    left: -5px;
    top: 5px;
  }

  .dg-1-2 {
    left: 125px;
    top: -15px;
    height: 141px;
    width: 111px;
  }
  .dg-1-2:hover {
    left: 130px;
    top: -20px;
  }

  .dg-1-3 {
    left: 150px;
    top: 0;
    height: 165px;
    width: 169px;
  }
  .dg-1-3:hover {
    left: 155px;
    top: -5px;
  }

  .dg-1-4 {
    left: 215px;
    top: 15px;
    height: 160px;
    width: 141px;
  }
  .dg-1-4:hover {
    left: 220px;
    top: 10px;
  }

  .dg-2-1 {
    width: 342px;
    height: 225px;
    left: 0;
    top: 65px;
  }
  .dg-2-1:hover {
    left: -5;
    top: 70px;
  }
  .dg-2-2 {
    width: 200px;
    height: 226px;
    left: 10px;
    top: -15px;
  }
  .dg-2-2:hover {
    left: 15px;
    top: -10px;
  }

  .dg-2-3 {
    width: 105px;
    height: 136px;
    left: 120px;
    top: -30px;
  }
  .dg-2-3:hover {
    left: 125px;
    top: -35px;
  }

  .dg-2-4 {
    width: 109px;
    height: 138px;
    left: 137px;
    top: -18px;
  }
  .dg-2-4:hover {
    left: 142px;
    top: -23px;
  }

  .dg-2-5 {
    width: 151px;
    height: 154px;
    left: 145px;
    top: -5;
  }
  .dg-2-5:hover {
    left: 150px;
    top: -10px;
  }

  .dg-2-6 {
    width: 140px;
    height: 160px;
    left: 205px;
    top: 5px;
  }
  .dg-2-6:hover {
    left: 210px;
    top: 0;
  }
  //third diagram

  .dg-3-1 {
    width: 200px;
    height: 230px;
    left: 150px;
    top: 60px;
  }
  .dg-3-1:hover {
    left: 155px;
    top: 65px;
  }

  .dg-3-2 {
    width: 226px;
    height: 296px;
    left: 15px;
    top: -15px;
  }
  .dg-3-2:hover {
    left: 10px;
    top: -20px;
  }

  .dg-3-3 {
    width: 109px;
    height: 137px;
    left: 95px;
    top: -30px;
  }
  .dg-3-3:hover {
    left: 100px;
    top: -35px;
  }

  .dg-3-4 {
    width: 137px;
    height: 137px;
    left: 115px;
    top: -18px;
  }
  .dg-3-4:hover {
    left: 120px;
    top: -23px;
  }

  .dg-3-5 {
    width: 153px;
    height: 155px;
    left: 158px;
    top: -10px;
  }
  .dg-3-5:hover {
    left: 163px;
    top: -15px;
  }

  .dg-3-6 {
    width: 136px;
    height: 156px;
    left: 220px;
    top: 0;
  }
  .dg-3-6:hover {
    left: 225px;
    top: -5px;
  }

  //third diagram end
}
@media all and (max-width: 1280px) and (min-width: 1190px) {
  .legend--distr-item {
    height: 150px;
    align-items: flex-start;
    //height: auto;
  }
  .custom-item {
    width: 40% !important;
    min-width: inherit !important;
  }
}
@media all and (max-width: 1189px) and (min-width: 990px) {
  .legend--distr-item {
    height: 200px;
    align-items: flex-start;
    //height: auto;
  }
}
@media all and (max-width: 1280px) and (min-width: 990px) {
  .caption--distr-item {
    height: 80px;
  }
  .distribution-block {
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .diagram--distr-item {
    margin-top: 45px;
  }
  .distribution__caption {
    padding-bottom: 20px;
  }
  //.caption--distr-item-small {
  //  min-height: 100px;
  //}
  .legend--distr-item {
    align-items: flex-start;
    //height: auto;
  }
  .legend-item {
    min-width: 50%;
    width: inherit;
    padding: 5px 0;
  }
  .legend-item__color {
    min-width: 12px;
  }
    .caption--distr-item {
    font-size: 20px;
  }
  .legend--distr-item {
    padding: 20px 0;
  }
}

@media all and (min-width: 320px) and (max-width: 990px) {
  .distribution-block {
    padding-bottom: 10px;
    padding-top: 60px;
  }
  .distribution__caption {
    padding-bottom: 30px;
  }
  .distr-item {
    height: 779px;
    width: 100%;
    padding-left: 0;
  }
  .legend--distr-item {
    padding: 39px 0;
  }
  .distr-row {
    flex-wrap: initial;
    position: relative;
    transform: none;
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    overflow: hidden;
  }

  .distr-item {
    flex: 1 0 100%;
    flex-basis: 50%;
    opacity: 0.5;
    margin-right: 10px;
    transition: opacity 1.5s cubic-bezier(.23,1,.32,1);
  }
  .diagram--distr-item {
    margin-top: 15px;
    height: 283px;
    width: 307px;
  }


  .distr-item--active {
    opacity: 1;
  }
  .legend--distr-item {
    padding: 39px 0;
    height: auto;
  }
  .legend-item {
    width: 100%;
  }
  .distr--left-nav {
    position: absolute;
    top: 40%;
    right: 7%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
  }
  .distr--right-nav {
    position: absolute;
    top: 30%;
    right: 7%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
  }

  .distr__arrow {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .distr__arrow--next {
    color: #fff;
    border: solid 3px #fff;
  }

  .distr__arrow--prev {
    color: #fff;
    border: solid 3px #fff;
  }
}

.distribution__caption {
  font-size: 40px;
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
  .distribution__caption {
    font-size: 36px;
  }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
  .distribution__caption {
    font-size: 34px;
  }
}

@media all and (max-width: 1280px) {
  .distribution__caption {
    font-size: 32px;
  }
}