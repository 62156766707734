.video-block {
  background-image: url('../img/video-block-bg.png');
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  height: 280px;
  display: flex;
  align-items: center;
}
.video-block__control {
  color: #ffffff;
  cursor: pointer;
}
.video-carousel__item {
  overflow-x: hidden;
}
.video-block__control--arrow:hover {
  color: #10e3fc;
}

.video-item {
  width: 225px;
  height: 135px;
  filter: grayscale(100%);
  cursor: pointer;
  background: url('../img/video-cover.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.video-item:hover {
  filter: none;
  background: url('../img/play.png'), url('../img/video-cover.png');
  background-position: center,center;
  background-repeat: no-repeat,no-repeat;
  background-size: initial, contain;
}

.video-carousel {
  width: 100%;
}

.video-carousel--inner {
  padding: 0 15%;
}

.video-carousel--inner .carousel-item.active,
.video-carousel--inner .carousel-item-next,
.video-carousel--inner .carousel-item-prev {
  display: flex;
}

.video-carousel--inner .carousel-item-right.active,
.video-carousel--inner .carousel-item-next {
  transform: translateX(25%) !important;
}

.video-carousel--inner .carousel-item-left.active,
.video-carousel--inner .carousel-item-prev {
  transform: translateX(-25%) !important;
}

.video-carousel--inner .carousel-item-right,
.video-carousel--inner .carousel-item-left{
  transform: translateX(0) !important;
}