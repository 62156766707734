.subscribe-block {
  background: url('../img/subscribe-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.btn-subscribe {
  position: relative;
  width: 600px;
  height: 64px;
  border-radius: 32px;
  border-color: #0cdaff;
  border-width: 3px;
  background-color: initial;
  font-family: HelveticaNeueCyr;
  font-size: 17px;
  font-weight: 500;
  color: #fefefe;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-subscribe:hover {
  background-image: linear-gradient(to right, #122f56, #0cdaff);
  background-position: -3px 0;
  background-size: 600px 64px;
  border-radius: 32px;
  border-color: transparent;
  border-width: 3px;
  box-shadow: 11.6px 12.4px 24px 0 rgba(12, 214, 251, 0.13);
  color: #fefefe;
}

.btn-subscribe:hover i {
  color: #fefefe;
}
.btn-subscribe:hover p {
  color: #fefefe;
}

.btn-subscribe i {
  width: 10%;
  color: #fefefe;
}
.btn-subscribe p {
  color: #fefefe;
  width: 90%;
  margin-bottom: 0;
  padding-right: 8%;
}

@media all and (min-width: 1920px) {
  .subscribe-block {
    background-size: cover;
  }
}

@media all and (min-width: 320px) and (max-width: 750px) {
  .subscribe-block div {
    width: 100%;
  }
  .btn-subscribe {
    width: 100%;
    font-size: 14px;
  }
  .btn-subscribe:hover {
    background-size: 102% 64px;
    background-position: center;
  }
  .btn-subscribe i {
    display: none;
  }
  .btn-subscribe p {
    width: 100%;
    padding-right: 0;
  }
}