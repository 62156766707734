.custom-nav {
  height: 64px;
  position: absolute;
  z-index: 1042;
  padding-bottom: 0;
  padding-top: 0;
  width: 100%;
  border-bottom: 1px solid rgba(182,213,240, 0.25);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  font-family: HelveticaNeueCyr-Roman, HelveticaNeueCyr;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: 0.1px;
  text-align: left;
  color: #ffffff;
}

.navbar-brand {
  display: flex;
}

.custom-nav--fixed {
  position: fixed;
  background: #1e2f57;
  height: 64px !important;
}

.custom-nav--fixed .navbar-collapse .navbar-nav {
  height: 64px !important;
 }
.custom-nav--fixed .navbar-collapse .navbar-nav .nav-item .dropdown-menu{
  background: rgb(30, 47, 87);
}
.dropdown-menu {
  height: auto;
  padding: 0;
  margin: 0;
}
.dropdown-item {
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  color: #a1e6ee;
}

.dropdown-item span {
  height: 100%;
  padding: 20px 0;
  border-bottom: 2px solid transparent;
}


.navbar-nav {
  height: 100%;
}
.navbar-collapse--custom {
  align-items: center;
  height: 64px;
}

.dropdown-toggle::after {
  display: none;
}

.nav-item {
  padding: 0 23px;
}
.first-itm {
  padding-left: calc(23px + 0.5rem);
}
.nav-item {
  display: flex;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;
}

.nav-link {
  height: 100%;
  display: flex;
  align-items: center;
}

.link:hover {
  text-decoration: none;
}

/* Show the dropdown menu on hover */
.dropdown-menu {
  display: flex;
  background: 0 0;
  border: none;
  transform: scale(1,0);
  transform-origin: 0 0;
  transition: transform .2s linear,-webkit-transform .2s linear;
}
.dropdown:hover .dropdown-menu {
  transform: scale(1,1);
}

.nav-link:hover {
  touch-action: none;
  border-bottom: 3px solid #0de4fe;
}

.nav-item a {
  font-family: HelveticaNeueCyr-Roman, HelveticaNeueCyr;
}

.show>.dropdown-menu {
  display: flex;
  background: none;
  border: none;
}

.dropdown-item:hover {
  color: white;
  background: none;
  border: none;
  //border-bottom: 2px solid #0de4fe;
}

.dropdown-item span:hover {
  color: white;
  background: none;
  border: none;
  border-bottom: 2px solid #fff;
}

.nav-link:hover {
  color: #26e7fe !important;
}

.nav-link {
  color: #ffffff !important;
}

.auth-btn {
  margin: 0 53px;
  display: flex;
  align-items: center;
  position: relative;
  background-image: url("/img/brdr.svg");
  background-repeat: no-repeat;
  height: 46px;
  //border: 4px solid transparent;
  //border-radius: 26px;
  //background: linear-gradient(orange, violet);
  //background-clip: padding-box;
  //padding: 10px;
  ///* just to show box-shadow still works fine */
  //box-shadow: 0 3px 9px black, inset 0 0 9px white;
}

.auth-btn:hover {
  color: #ffffff !important;
}

.auth-btn::after {
  //position: absolute;
  //top: -4px; bottom: -4px;
  //left: -4px; right: -4px;
  //background: linear-gradient(red, blue);
  //content: '';
  //z-index: -1;
  //border-radius: 26px;
}

.auth-btn-styled {
  display: flex;
  align-items: center;
  justify-content: center;
  //padding-top: 5px;
  width: 115px;
  height: 100%;
  background-color: initial;
  text-transform: uppercase;
  font-family: HelveticaNeueCyr;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.1px;
  text-align: center;
  border: none;
  cursor: pointer;
}

.auth-btn-styled:hover {
  text-decoration: none;
}

.log-in {
  color: #0ce5ff;
  position: relative;
  //padding-left: 2px;
}

.log-in:hover {
  background: #0ce5ff;
  color: #ffffff;
  border-radius: 57px 0 0 57px;
}

.log-in::after {
  position: absolute;
  top: 10px;
  right: 0;
  background: rgba(182,213,240,0.25);
  content: '';
  height: 25px;
  width: 1px;
  //content: '';
  //z-index: -1;
  //border-radius: 26px;
}

.sign-up {
  color: #e87239;
  //padding-right: 2px;
}

.sign-up:hover {
  background: #e87239;
  color: #ffffff;
  border-radius: 0 57px 57px 0;
}

.social--hide {
  background: #083151;
}

.social--hide::before {
  //content: '';
  //position: absolute;
  //top: 0;
  //bottom: 0;
  //right: 100%;
  //width: 32px;
  //background: -webkit-linear-gradient(left, rgba(8,49,81, 0) 0%, rgba(8,49,81, 1) 100%);
  //background: linear-gradient(to right, rgba(8,49,81, 0) 0%, rgba(8,49,81, 1) 100%);
}

/* SOCIAL NETWORK MENU LIST START*/
.top-soc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  height: 64px;
}
.top-soc .hide {
  height: 80%;
  position: absolute;
  top: 10%;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  //margin: 0 34px 0 0;
  //margin-left: -1000px;
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
  border-radius: 20px;
  background-color: #193267;
  padding-left: 20px;
  padding-right: 20px;
}

.top-soc .show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.top-soc .more, .top-soc .link, .top-soc2 .link {
  margin: 0 4px;
  position: relative;
}
.ic.small {
  width: 24px;
  height: 24px;
}

.ic {
  display: flex;
  align-items: center;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.top-soc .more {
  width: 7px;
  height: 24px;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  margin-left: 1px;
}
.top-soc .more > * {
  //margin: 0 1px;
  //width: 4px;
  //height: 4px;
  //background: #0cd6f1;
  //border-radius: 50%;
  //-webkit-transition: all 0.2s linear;
  //transition: all 0.2s linear;
}

.link {
  color: white;
}

.show > .link {
  padding-right: 27px;
}

.social-icon {
  font-size: 15px;
}
.social-icon:hover {
  //color: #fff;
  text-decoration: none;
  font-size: 15px;
}
/* SOCIAL NETWORK MENU LIST END*/

@media all and (min-width: 1900px) {
  .custom-nav {
    height: 108px;
    padding-left: 36px;
  }

  .navbar-collapse--custom {
    height: 108px;
  }

  .navbar-brand {
    margin-right: 45px;
  }
}

@media all and (max-width: 1690px) {

}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .nav-item {
    padding: 0 10px;
  }
  .first-itm {
    padding-left: calc(10px + 0.5rem);
  }
}

@media all and (max-width: 1537px) and (min-width: 1281px) {
  .nav-item {
    padding: 0 5px;
  }

  .auth-btn {
    margin: 0 25px;
  }
  .language-selector__wrap {
    width: 100px;
  }
  //.navbar-expand-lg .navbar-nav .nav-link {
  //  padding-right: 0.1em;
  //  padding-left: 0.1em;
  //}
}

@media all and (max-width: 1280px) {
  .navbar-brand img {
    width: 130px;
  }
  .nav-item {
    padding: 0;
    margin-left: 0em;
    margin-right: 0em;
  }
  .first-itm {
    padding-left: calc(1rem);
  }
  .auth-btn {
    margin: 0 5px;
  }
  .lang-selector {
    padding: 0 14px;
  }
  .custom-nav {
    font-size: 14px;
  }
  .dropdown-menu {
    font-size: 12px;
  }
  .top-soc {
    height: 50px;
  }


}

@media all and (max-width: 1280px) {
  .navbar-brand img {
    width: 130px;
  }
  .nav-item {
    padding: 0;
    margin-left: 0em;
    margin-right: 0em;
  }
  .auth-btn {
    margin: 0 5px;
  }
  .lang-selector {
    padding: 0 14px;
  }
  .custom-nav {
    font-size: 14px;
  }
  .dropdown-menu {
    font-size: 12px;
  }
  .top-soc {
    height: 50px;
  }
}

@media all and (max-width: 1280px) and (min-width: 1199px) {
  .navbar-toggleable-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media all and (max-width: 1199px) and (min-width: 980px) {
  .first-itm {
    padding-left: calc(0.5rem);
  }
}



@media all and (max-width: 980px) {
  .navbar-nav {
    padding: 10px;
  }
  .nav-item.dropdown {
    flex-direction: column;
  }
  .dropdown-menu {
    //display: flex !important;
    flex-direction: row;
    -webkit-transform: scale(1,1);
    transform: scale(1,1);
    background: none !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  .dropdown-item {
    width: auto;
    padding: 0 10px;
  }
}
@media all and (min-width: 1028px) and (max-width: 1199px) {
  .show>.link {
    padding-right: 10px;
  }
}

@media all and (min-width: 991px) and (max-width: 1028px) {
  .show>.link {
    padding-right: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1028px)  {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .4rem;
    padding-left: .4rem;
  }
}

@media all and (min-width: 320px) and (max-width: 991px) {
  .navbar-inverse .navbar-toggler {
    border: none;
  }

  .navbar-toggler {
    padding: 0.25em 0;
  }

  .navbar-toggler .navbar-toggler-icon {
    //background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='36' height='35'>
    //<path fill='%2303102D' fill-rule='evenodd' d='M35.988 4.122L31.886.034 18.467 13.407 5.048.034.946 4.122l13.419 13.373L.946 30.867l4.102 4.088 13.419-13.373 13.419 13.373 4.102-4.088-13.42-13.372 13.42-13.373z'/>
    //</svg>");
    background-image: url('/img/menu-close.png');
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .collapsed .navbar-toggler-icon {
    //background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='65' height='36'>
    //<path fill='%23EFF2F5' fill-rule='evenodd' d='M0 0h65v4.001H0V0z'/>
    //<path fill='%230CE5FF' fill-rule='evenodd' d='M13 16h52v3.999H13V16z'/>
    //<path fill='%23EFF2F5' fill-rule='evenodd' d='M28 32h37v4H28v-4z'/>
    //</svg>");
    background-image: url('/img/menu_open.png');
    background-size: 65px 30px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .navbar-toggler {
    height: 64px;
    width: 64px;
    right: 0;
    border-radius: 0;
    margin: 0;
    background: #26e7fe;
  }
  .collapsed.navbar-toggler {
    background: none;
  }

  .collapse.show {
    height: 100vh !important;
    transition: height 0.4s;
  }

  .custom-nav {
    padding: 0;
    z-index: 1999999;
  }

  .navbar-brand {
    position: relative;
    z-index: 99999999;
    padding: 10px 15px;
  }

  .custom-nav--fixed .navbar-collapse .navbar-nav {
    height: auto !important;
  }

  .zod-nav {
    width: 100vw;
    z-index: 1000;
    background: rgba(10,28,56,.9);
    padding-top: 20px;
  }

  .navbar-nav {
    height: auto;
    width: 100%;
    order: 2;
  }

  .collapse.show {
    height: 100vh !important;
    transition: height 0.4s;
  }
  .navbar-toggler {
    z-index: 9999999999;
  }
  .nav-link {
    font-weight: 600;
    font-size: 18px;
  }
  .auth-btn {
    justify-content: center;
    background-position: center;
  }

  .nav-item {
    justify-content: center;
    min-height: 40px;
  }
  .navbar-brand {
    position: relative;
    z-index: 99999999;
  }

  .top-soc > .hide {
    transform: scale(1, 1) !important;
  }
  .top-soc > .show {
    transform: scale(0, 1);
    display: none;
  }

  .top-soc .hide {
    background: none;
    background-color: unset;
    position: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .top-soc .more {
    display: none;
  }
  .dropdown-menu {
    transform: scale(1,0);
    display: none;
    height: 100vh;
    position: absolute;
    top: 0;
    background: rgba(10,28,56,.9) !important;
    padding-top: 80px;
    flex-direction: column;
    width: 100vw;
    align-items: center;
  }
}
@media all and (max-width: 736px) {

}
@media all and (max-width: 360px) {
  .nav-link {
    padding: 5px;
  }
}