.ofer {
  position: relative;
  max-width: 845px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 20px auto;
}

.ofer > button.mfp-close {
  height: 60px;
  width: 60px;
  background: #0ce5ff;
}
.ofer__caption {
  height: 262px;
  width: 100%;
  border-radius: 15px 0 0 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: HelveticaNeueCyr;
  font-size: 50px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background: url('/img/ofer_bg.png');
  background-position: center;
}
.ofer__caption--selected {
  color: #26e7fe;
  border-bottom: 2px solid #26e7fe;
}

.ofer__body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 268px;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
  background: #ffffff;
  font-family: HelveticaNeueCyr-Roman;
  font-size: 20px;
  font-weight: 500;
  line-height: 2.5;
  letter-spacing: 0.1px;
  text-align: left;
  color: #111111;
}


.btn-ofer {
  margin: 3% 0;
  position: relative;
  width: 410px;
  height: 64px;
  border-radius: 32px;
  border-color: #3f65c0;
  border-width: 3px;
  background-color: initial;
  font-family: HelveticaNeueCyr;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.1px;
  color: #3f65c0;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-ofer:hover {
  background-image: linear-gradient(to right, #122f56, #0cdaff);
  background-position: -3px 0;
  background-size: 410px 64px;
  box-shadow: 11.6px 12.4px 24px 0 rgba(12, 214, 251, 0.13);
  border-radius: 32px;
  border-color: transparent;
  border-width: 3px;
  //padding: .5rem 1rem;
  //border: 1px solid transparent;
  //background-size: 425px 64px;
  //background-position-x: -3px;
}

//.btn-ofer:hover i {
//    padding-left: 3px;
//    padding-top: 3px;
//}

.btn-ofer i {
  color: #3f65c0;
  width: 10%;
}
.btn-ofer p {
  color: #1d2a33;
  width: 90%;
  margin-bottom: 0;
  padding-right: 8%;
}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .ofer__caption {
    height: 162px;
  }
  .ofer__body {
    padding: 30px 0 0;
    font-size: 18px;
  }
}


@media all and (max-width: 1280px) {
  .ofer__caption {
    height: 162px;
  }
  .ofer__body {
    padding: 30px 0 0;
    font-size: 18px;
  }
}

@media all and (min-width: 320px) and (max-width: 990px) {
  .ofer {
    margin-top: 75px;
  }
  .ofer__body {
    font-size: 16px;
  }
  .ofer__caption {
    font-size: 36px;
  }

  .ofer__body p {
    width: 90%;
    text-align: center;
  }
  .btn-ofer {
    width: 90%;
    height: 54px;
  }
  .ofer>button.mfp-close {
    height: 40px;
    width: 40px;
  }
}

@media all and (max-width: 350px) {
  .ofer__caption {
    font-size: 30px;
  }
}