.advisors-block-cover {
  padding: 0;
  background: #ffffff;
}
.advisors-block {
  width: 100%;
  padding-bottom: 100px;
  padding-top: 100px;
  background: url("/img/advisors-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.advisors__caption {
  padding-bottom: 64px;
  font-family: HelveticaNeueCyr;
  font-size: 50.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.73;
  letter-spacing: 0.3px;
  text-align: left;
  color: #1d2a33;
  text-transform: uppercase;
}

.advisors__caption--marked {
  color: #3f66c2;
}

.advisors-item {
  width: 33.33%;
  //height: 175px;
  margin: 32px 0;
  display: flex;
  flex-direction: row;
}

.advisors-item__photo {
  height: 131px;
  width: 131px;
  align-self: flex-start;
  border-radius: 50%;
  overflow: hidden;
}

.advisors-item__info {
  padding-left: 39px;
  padding-top: 38px;
  max-width: 320px;
}

.name--advisors-item {
  font-family: HelveticaNeueCyr;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1d2a33;
}

.info--advisors-item {
  position: relative;
  padding-bottom: 28px;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 300;
  color: #607787;
  letter-spacing: 0.1px;
  line-height: 1.44;
  text-align: left;
}

.info--advisors-item::after {
  position: absolute;
  width: 45%;
  height: 2px;
  bottom: 0;
  left: 0;
  content: " ";
  background-color: #3f66c2;
}

.social--advisors-item {
  padding-top: 29px;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 300;
  color: #b1b5b8;
  letter-spacing: 0.1px;
  text-align: left;
}

.social__item--advisors {
  padding-right: 15px;
}

.social__item--advisors:hover {
  color: #3f65c0;
}

.btn-advisors {
  position: relative;
  width: 410px;
  height: 64px;
  margin-top: 70px;
  border-radius: 32px;
  border-color: #3f65c0;
  border-width: 3px;
  background-color: initial;
  font-family: HelveticaNeueCyr;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #1d2a33;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-advisors i {
  width: 10%;
  color: #3f65c0;
}
.btn-advisors p {
  width: 90%;
  margin-bottom: 0;
  padding-right: 8%;
}

.btn-advisors:hover {
  border: none;
  background-image: linear-gradient(to right, #122f56, #0cdaff);
  box-shadow: 11.6px 12.4px 24px 0 rgba(12, 214, 251, 0.13);
  color: #fefefe;
}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .advisors-item__info {
    max-width: 250px;
  }
}

@media (max-width: 1280px) {
  .advisors__caption {
    font-size: 40.5px;
  }
  .name--advisors-item {
    font-size: 18px;
  }
  .info--advisors-item {
    font-size: 14px;
  }
  .advisors-item__info {
    max-width: 240px;
  }
}

@media all and (min-width: 991px) and (max-width: 1199px) {
  .advisors-item {
    width: 45.33%;
  }
}

@media all and (min-width: 320px) and (max-width: 990px) {
  .advisors-block {
    padding-bottom: 50px;
    padding-top: 60px;
  }
  .advisors__caption {
    padding-bottom: 20px;
  }
  .advisors-item {
    width: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  .advisors-item__photo {
    align-self: inherit;
  }
  .advisors-item__info {
    width: 100%;
    padding-top: 25px;
    padding-left: 0;
    align-self: inherit;
  }
  .name--advisors-item {
    text-align: center;
  }
  .info--advisors-item {
    text-align: center;
    width: 100%;
  }
  .social--advisors-item {
    justify-content: center;
    display: flex;
  }
  .info--advisors-item::after {
    width: 87px;
    left: 74px;
  }
}

.advisors__caption {
  font-size: 40px;
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
  .advisors__caption {
    font-size: 36px;
  }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
  .advisors__caption {
    font-size: 34px;
  }
}

@media all and (max-width: 1280px) {
  .advisors__caption {
    font-size: 32px;
  }
}