.partners-block {
  padding-top: 120px;
  //background: url("/img/partners-bg.png");
  background: url("/img/advisors-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.partners-block-cover {
  padding: 0;
  background: #fff;
}

.partners__caption {
  padding-bottom: 60px;
  font-family: HelveticaNeueCyr;
  font-size: 50.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.73;
  letter-spacing: 0.3px;
  text-align: left;
  //color: #ffffff;
  color: #1d2a33;
  text-transform: uppercase;
}

.partners-item {
  width: 20%;
  padding: 38px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partners-item img {
  max-height: 100%;
}

.partners-item--shodow {
  filter: drop-shadow(0 0 25px rgba(100,0,0,0.8));
}

@media all and (max-width: 1690px) and (min-width: 1280px) {
  .partners-item img {
    max-height: 100%;
    max-width: 90%;
  }
  .partners-item {
    padding: 15px 0;
  }
}

@media (max-width: 1280px) {
  .partners-item {
    width: 25%;
    padding: 0;
  }

  .partners__caption {
    font-size: 40.5px;
  }
  .partners-item img {
    max-height: 100%;
    max-width: 80%;
  }
}

@media all and (min-width: 320px) and (max-width: 768px) {
  .partners-block {
    padding-top: 60px;
  }
  .partners-item {
    width: 80%;
    padding: 0 0;
  }
  .partners-item img {
    max-height: inherit;
  }
}

.partners__caption {
  font-size: 40px;
}

@media all and (max-width: 1900px) and (min-width: 1669px) {
  .partners__caption {
    font-size: 36px;
  }
}

@media all and (max-width: 1668px) and (min-width: 1280px) {
  .partners__caption {
    font-size: 34px;
  }
}

@media all and (max-width: 1280px) {
  .partners__caption {
    font-size: 32px;
  }
}